import React, { useState } from 'react';
import { Col, Form, Button, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TooltipInfoIcon, Eye, EyeSlash } from '@icons';
import { createUser, getUsers } from '@lib/api';
import { SuccessToast, DangerToast } from '@components/GlobalToastList';

const initialState = {
  email: '',
  password: '',
};

const UserCreation = () => {
  const [user, setUser] = useState(initialState);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/; // eslint-disable-line
  //console.log('user', user);

  const clickHandler = () => setShowPass(!showPass);

  const onChange = (e) => {
    //console.log('e', e);

    setUser(() => ({
      ...user,
      [e.target.name]: e.target.value,
    }));
  };

  const onBlur = (value, regex, setState) => {
    if (value && value.match(regex)) {
      setState(false);
    } else {
      setState(true);
    }
  };

  const submit = async (e) => {
    e?.preventDefault();

    if (!user.email || !user.password) {
      DangerToast(
        'Attenzione',
        'Tutti i campi sono obbligatori.'
      );
      return;
    }

    const users = await getUsers(2); //creazione solo utenti di ruolo 2

    if (users.length > 0 && 
        users.filter(retrievedUser => retrievedUser.email === user.email) && 
        users.filter(retrievedUser => retrievedUser.email === user.email).length > 0) {
      setEmailInvalid(true);

      DangerToast(
        'Attenzione',
        'Email già esistente.'
      );
      return;
    }

    if (!user.email.match(emailRegex)) {
      DangerToast(
        'Attenzione',
        `Rispetta i criteri dell'e-mail.`
      );
      return;
    }

    if (!user.password.match(passwordRegex)) {
      DangerToast(
        'Attenzione',
        'Rispetta i criteri della password.'
      );
      return;
    }

    const result = await createUser(user.email, user.password, 2);

    //console.log('result:', result);

    if (result.accessToken) {
      SuccessToast(
        'Operazione conclusa',
        `Utente creato correttamente.`
      );

     setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      DangerToast(
        'Attenzione',
        `Errore nella creazione dell'utente.`
      );
    }
  };

  return (
    <div id="user-creation">
      <Col sm={8}>
        <Form onSubmit={e => submit(e)} id="user-creation-form" autoComplete="off">
          <div className="simple-box" style={{ boxShadow: 'none', margin: 0, paddingTop: '0.4rem'}}>
            <h5 className="title-simple-box margin-title-box">
              Crea un nuovo utente
            </h5>

            <Form.Group as={Col} controlId="email">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                name="email"
                size="lg"
                defaultValue={user.email}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e.target.value, emailRegex, setEmailInvalid)}
                isInvalid={emailInvalid}
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="password">
              <Form.Label>
                Password
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 200, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip">La password deve contenere: 
                    <ul style={{textAlign: 'left', paddingLeft: '20px', margin: 'auto'}}>
                      <li>una lettera maiuscola,</li>
                      <li>un numero,</li>
                      <li>un carattere speciale,</li>
                      <li>una lunghezza minima di 8 caratteri.</li>
                    </ul>
                  </Tooltip>}
                >
                  <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
                </OverlayTrigger>
              </Form.Label>
              <InputGroup>          
                <Form.Control
                  type={showPass ? "text" : "password"}
                  name="password"
                  size="lg"
                  defaultValue={user.password}
                  onChange={(e) => onChange(e)}
                  onBlur={(e) => onBlur(e.target.value, passwordRegex, setPasswordInvalid)}
                  isInvalid={passwordInvalid}
                  autoComplete="new-password"
                />
              <InputGroup.Text className="addon-for-number">
                  {showPass ? <Eye className="eye" onClick={clickHandler} /> : <EyeSlash className="eye" onClick={clickHandler} />}
                </InputGroup.Text>
              </InputGroup> 
            </Form.Group>
          </div>
            
          <Button
            type="submit" 
            form="user-creation-form" 
            variant="primary" 
            className="user-button create-edit-user-button"
          >
            SALVA
          </Button>
        </Form>
      </Col>
    </div>
  );
};

export default UserCreation;