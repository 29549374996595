import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { getTranslations, getProject } from '@lib/api';
import { Redirect } from 'react-router-dom';
import GeneralDataDetails from './components/generalDataDetails';
import ESGDetails from './components/esgDetails';
import SroiDetails from './components/sroiDetails';
import MaterialityDetails from './components/materialityDetails';
import ProjectHeader from '@components/project/ProjectHeader';
import RiskDetails from './components/riskDetails';

const Progetto = (props) => {
  const { projectId } = useParams();
  const [translations, setTranslations] = useState([]);
  const [project, setProject] = useState({});
  const [isInDetails, setIsInDetails] = useState('general');
  const printRef = React.useRef();
  const [showCells, setShowCells] = useState(false);

  useEffect(() => {
    getTranslations().then((retrievedTranslation) => {
      setTranslations(retrievedTranslation);
    });

    getProject(projectId).then((retrievedProject) => {
      setProject(retrievedProject);
    });

    setIsInDetails(
      props.location.pathname === `/progetti/${projectId}` ? true : false
    );
  }, [projectId, props.location.pathname]);

  if (!projectId || !project) return <Redirect to="/progetti" />;

  return (
    <div id="dashboard" ref={printRef} className="megaContent">
      <div className="container-fluid">
        <ProjectHeader project={project} isInDetails={isInDetails} translations={translations} printRef={printRef} setShowCells={setShowCells} />
        
        {project.sectorCode && ( project.sectorCode === 'EVENTI')? 
         <GeneralDataDetails className="mb-5" currentProject={project} sectorCode={project.sectorCode} proponentCode={project.proponentCode} translations={translations} /> : 
         <>
            <Row className="gap-1">
               <Col >
               <GeneralDataDetails className="mb-5" currentProject={project} sectorCode={project.sectorCode} proponentCode={project.proponentCode} translations={translations} />
               </Col>

               <Col xs={4}>
               <SroiDetails className="mb-5" currentProject={project} sectorCode={project.sectorCode} proponentCode={project.proponentCode} />
               </Col>
            </Row>
         </>}

         <RiskDetails currentProject={project} />
         {/* {process.env.REACT_APP_DEMO_USER && process.env.REACT_APP_DEMO_PASSWORD ? 
            null : 
            <RiskDetails currentProject={project} />
         } */}

         <ESGDetails currentProject={project} />

         <MaterialityDetails currentProject={project} showCells={showCells} setShowCells={setShowCells} />
         {/* {process.env.REACT_APP_DEMO_USER && process.env.REACT_APP_DEMO_PASSWORD ? 
            null : 
            <MaterialityDetails currentProject={project} showCells={showCells} setShowCells={setShowCells} />
         } */}
      </div>
    </div>
  );
};

export default Progetto;
