import React from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import UsersList from '@components/usersManagement/UsersList';
import UserCreation from '@components/usersManagement/UserCreation';
import UserModification from '@components/usersManagement/UserModification';
import UserDeletion from '@components/usersManagement/UserDeletion';

const Administration = () => {
  return (
    <div id="administration" className="megaContent">
      <div className="container-fluid">
        <Tab.Container id="left-tabs-example" defaultActiveKey="list">
          <Row className="justify-content-center">
            <Col sm={3} className="border-tab">
              <Nav variant="pills" className="flex-column nav-admin">
                <Nav.Item>
                  <Nav.Link eventKey="list">Lista utenti</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="create">Creazione utente</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="edit">Modifica utente</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="delete">Eliminazione utente</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={8}>
              <Tab.Content>
                <Tab.Pane eventKey="list">
                  <UsersList />
                </Tab.Pane>
                <Tab.Pane eventKey="create">
                  <UserCreation />
                </Tab.Pane>
                <Tab.Pane eventKey="edit">
                  <UserModification />
                </Tab.Pane>
                <Tab.Pane eventKey="delete">
                  <UserDeletion />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Administration;
