import moment from 'moment';
import i18n from '@lib/i18n';
import Currency from '@lib/currency';

const config = {
  dateFormat: 'DD/MM/Y',
};

const formatters = {
  currency: new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: Currency.currency,
  }),
  rounder: new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }),
  percent: new Intl.NumberFormat(i18n.language, {
    style: 'percent',
    unit: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }),
};

i18n.on('languageChanged', () => {
  formatters.currency = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: Currency.currency,
  });
  formatters.rounder = new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  formatters.percent = new Intl.NumberFormat(i18n.language, {
    style: 'percent',
    unit: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  // eslint-disable-next-line default-case
  switch (i18n.language) {
  case 'it':
  case 'es':
    config.dateFormat = 'DD/MM/Y';
    break;
  case 'en':
    config.dateFormat = 'MM/DD/Y';
    break;
  }
});

Currency.on('currencyChanged', () => {
  formatters.currency = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: Currency.currency,
  });
});

export const currency = (value) => formatters.currency.format(value);
export const rounder = (value) => formatters.rounder.format(value);
export const percent = (value) => formatters.percent.format(value / 100);
export const date = (value) => moment(value).format(config.dateFormat);
export const chartColors = [
  '#C2C923',
  '#08A2AA',
  '#CB1B4A',
  '#FFB317',
  '#053D58',
  '#378288',
  '#5BCFD4',
];
export const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);
