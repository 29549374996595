import React from 'react';
import { date } from '@lib/helpers';
import { Button } from 'react-bootstrap';
import { downloadPDF } from 'components/form/downloadPDF';
import { Print } from '@icons';

const optionsStatus = [{
  value: 'DRAFT',
  label: 'Bozza'
},
{
  value: 'SUBMITTED',
  label: 'Inviato'
},
{
  value: 'DELETED',
  label: 'Eliminato'
}];

const ProjectHeader = ({ project, isInDetails, translations, printRef, setShowCells }) => {
  //console.log('project', project);
  const url = window.location.href;

  const printDetails = () => {
      setShowCells(true);

      if ([...printRef.current.getElementsByClassName('materiality-block')].length === 0) {
         downloadPDF(printRef.current, `NDG${project.clientId}-rep${project.requestId}`);

      } else {
         const materialityBlocks = [...printRef.current.getElementsByClassName('materiality-block')];
         const showButton = document.getElementById('show-more');

         materialityBlocks.forEach(block => {
            block.classList.remove('fade-in-div');
         });

         showButton.style.display = 'none';

         downloadPDF(printRef.current, `NDG${project.clientId}-rep${project.requestId}`).then(() => {
            materialityBlocks.forEach(block => {
               block.classList.add('fade-in-div');
            });
   
            showButton.style.display = 'initial';
         });
      }
   };

   return (
            <>
               <div className="row">
                  <div className="col">
                     <h2>
                        <span className="scenario-details">{translations.filter(trans => trans.code === 'ICS002').map(trans => trans.text)}: {project.clientId}</span>
                        <span className="scenario-details">{translations.filter(trans => trans.code === 'ICS003').map(trans => trans.text)}: {project.requestId}</span>
                        <span className="scenario-details">
                           Status: {optionsStatus.filter(opt => opt.value === project.status).map(option => option.label)}
                        </span>
                        {project.status === 'SUBMITTED' && project.submittedAt ? (
                           <span className="scenario-details">
                              Inviato il: {project.submittedAt}
                           </span>
                        ) : null}
                        <span className="scenario-details">
                           Creato: {date(project.createdAt)}
                        </span>
                        <span className="scenario-details no-pipe">
                           Aggiornamento:{' '}
                           {project.updatedAt ? date(project.updatedAt) : 'n.d.'}
                        </span>
                        {url.includes('progetti') ?
                           <span className="scenario-details no-pipe pl-0">
                              <Button variant='link' onClick={printDetails}>
                                 <Print title={translations.filter(trans => trans.code === 'ICS105').map(trans => trans.text)} className="m-0 second-color" />
                              </Button>
                           </span> :
                           null
                        }
                     </h2>
                  </div>
               </div>
               {isInDetails ? (
                  <div className="row">
                     <div className="col">
                        <p className="px-3">{project.description}</p>
                     </div>
                  </div>
               ) : null}
            </>
         );
};
export default ProjectHeader;
