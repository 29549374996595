import React, { Component } from 'react';

class Ico98 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg
        id="ico-98"
        viewBox="0 0 117.62 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        height={this.props.height}
        width={this.props.width}
      >
        <path
          d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z"
          className="esagono"
        />
        <g transform="translate(-20, -20) scale(0.11)" style={{transformOrigin: 'center'}}>
         <path className="ico" d="M300.057,83.064v-15.3h15.3h24.135L318.851,0H159.117L7.062,499.168h135.157l24.575-105.977h139.769l24.574,105.977
            h139.769l-85.312-280.072h-14.217h-15.3v-15.3v-40.713h-40.714h-15.3v-15.3V83.064H300.057z M190.604,291.055l8.449-34.557
            c12.286-47.616,24.572-107.513,35.325-158.199h3.072c12.286,49.915,24.576,110.583,36.861,158.199l8.449,34.557H190.604z"/>
         <polygon className="ico" transform="translate(-80, -60) scale(1.3)" points="492.106,83.064 436.092,83.064 436.092,27.047 371.374,27.047 371.374,83.064 344.152,83.064 315.357,83.064 
            315.357,147.783 363.868,147.783 371.374,147.783 371.374,172.422 371.374,203.796 380.93,203.796 436.092,203.796 
            436.092,147.783 492.106,147.783"/>
         </g>
      </svg>
    );
  }
}
export default Ico98;
