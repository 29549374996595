import React, { Component } from 'react';

class Ico21 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg
        id="ico-21"
        viewBox="0 0 117.62 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        height={this.props.height}
        width={this.props.width}
      >
        <path
          d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z"
          className="esagono"
        />
        <g transform="translate(28.8,28)">
          <path
            d="M23.0283 74.0962H13.0919C10.9597 74.0962 8.91268 73.1618 7.54803 71.5054C6.18339 69.8489 5.629 67.7253 6.0128 65.6016L10.1494 44.1102C11.9831 34.6812 20.299 27.8005 29.9368 27.8005C31.9838 27.8005 33.9881 28.0978 35.9498 28.7349C36.2483 28.8199 36.5468 28.8624 36.8453 28.8624C38.1247 28.8624 39.2761 28.0129 39.6599 26.7812C40.0864 25.3796 39.4467 23.9355 38.21 23.2984C39.7878 19.9005 38.5938 15.8656 35.4807 13.7419L40.129 5.28977C40.6407 4.35536 40.5981 3.29353 40.0864 2.35913C39.532 1.46719 38.5938 0.915039 37.5276 0.915039H21.1092C20.0431 0.915039 19.1049 1.46719 18.5505 2.35913C17.9961 3.25106 17.9961 4.35536 18.5079 5.28977L23.1562 13.7419C21.5357 14.8037 20.4269 16.4177 19.9578 18.2441C19.4887 20.0704 19.7446 21.9817 20.5975 23.6382C12.239 26.8661 6.05545 34.214 4.39229 42.9634L0.255697 64.4548C-0.469272 68.2774 0.511568 72.1849 3.02764 75.2005C5.50106 78.2161 9.2112 79.915 13.0919 79.915H23.0283C24.6488 79.915 25.9708 78.5984 25.9708 76.9844C25.9708 75.3704 24.6488 74.0962 23.0283 74.0962ZM29.4677 12.5102H29.1692L26.0987 6.90375H32.5808L29.4677 12.5102ZM27.4207 18.414H31.2161C32.197 18.414 33.0072 19.221 33.0072 20.1978C33.0072 21.1747 32.197 21.9817 31.2161 21.9817H27.4207C26.4399 21.9817 25.6296 21.1747 25.6296 20.1978C25.6723 19.1785 26.4399 18.414 27.4207 18.414Z"
            className="ico"
          />
          <path
            d="M44.6487 31C31.0875 31 19.9998 42.0005 19.9998 55.5495C19.9998 69.0559 31.0449 80.0989 44.6487 80.0989C58.2099 80.0989 69.2977 69.0984 69.2977 55.5495C69.2977 42.0005 58.2525 31 44.6487 31ZM44.6487 36.9038C54.9689 36.9038 63.37 45.271 63.37 55.5495C63.37 65.828 54.9689 74.1952 44.6487 74.1952C34.3286 74.1952 25.9274 65.828 25.9274 55.5495C25.9701 45.2285 34.3286 36.9038 44.6487 36.9038Z"
            className="ico"
          />
          <path
            d="M42.4934 71V68.2503C39.4202 67.7481 36.589 66.3042 33.9998 63.9186L37.3754 59.6622C39.2629 61.3698 40.9689 62.4621 42.4934 62.9392V57.3269C39.9526 56.6488 38.0893 55.7448 36.9036 54.6148C35.7178 53.4597 35.125 51.8149 35.125 49.6804C35.125 47.5209 35.8025 45.7505 37.1577 44.3694C38.5128 42.9631 40.2913 42.1219 42.4934 41.8457V40H46.341V41.921C48.7608 42.2726 51.1685 43.3147 53.5642 45.0474L50.5878 49.4921C49.0633 48.3621 47.6477 47.6087 46.341 47.2321V52.6561C48.9544 53.3593 50.8782 54.3009 52.1123 55.4812C53.3706 56.6614 53.9998 58.3313 53.9998 60.4909C53.9998 62.6505 53.298 64.4334 51.8945 65.8396C50.5152 67.2458 48.664 68.0871 46.341 68.3633V71H42.4934ZM48.1921 60.7922C48.1921 60.2649 48.059 59.8254 47.7929 59.4739C47.5267 59.1223 47.0427 58.7959 46.341 58.4945V63.1276C47.5751 62.7258 48.1921 61.9473 48.1921 60.7922ZM42.4934 47.0814C41.4529 47.4581 40.9326 48.1486 40.9326 49.1531C40.9326 50.1576 41.4529 50.9234 42.4934 51.4508V47.0814Z"
            className="ico"
          />
        </g>
      </svg>
    );
  }
}
export default Ico21;
