import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getTranslations } from '@lib/api';
import { Ico3, Ico99, Ico100 } from '@icons/new';
import { TooltipInfoIcon } from '@icons';

// const riskColor = [
//   { code: 'popFr4', color: '#181a3b' }, //yankee blue
//   { code: 'popFr3', color: '#3A4A5D' }, //police blue
//   { code: 'popFr2', color: '#0AA1AA' }, //munsell
//   { code: 'popFr1', color: '#28B8A5' }, //light sea green
//   { code: 'popFrAA', color: '#C2C923' }, //acid green
//   { code: 'popFrP3p4', color: '#47C59D' }, //ocean green
//   { code: 'popIdr3', color: '#1C2743' }, //yankee blue
//   { code: 'popIdr2', color: '#3A4A5D' }, //police blue
//   { code: 'popIdr1', color: '#0AA1AA' }, //munsell
// ];

// const colorsIsvm = [
//   { code: 'popFr4', color: '#1C2743' }, //yankee blue
//   { code: 'popFr3', color: '#3A4A5D' }, //police blue
//   { code: 'popFr2', color: '#0AA1AA' }, //munsell
//   { code: 'popFr1', color: '#28B8A5' }, //light sea green
// ];
const riskColor = [
  { code: 'popFr4', color: 'rgba(115, 0, 0, .7)' }, //dark red
  { code: 'popFr3', color: 'rgba(230, 0, 0, .7)' }, //red
  { code: 'popFr2', color: 'rgba(230, 104, 0, .7)' }, //orange
  { code: 'popFr1', color: 'rgba(245, 203, 64, .7)' }, //ocra
  { code: 'popFrAA', color: 'rgba(255, 255, 115, .7)' }, //yellow
  { code: 'popFrP3p4', color: 'rgba(102, 106, 107, .7)' }, //dark grey
  { code: 'popIdr3', color: 'rgba(115, 0, 0, .7)' }, //dark red
  { code: 'popIdr2', color: 'rgba(230, 0, 0, .7)' }, //red
  { code: 'popIdr1', color: 'rgba(230, 104, 0, .7)' }, //orange
];

const colorsIsvm = [
  { code: 'popFr4', color: 'rgba(115, 0, 0, .7)' }, //dark red
  { code: 'popFr3', color: 'rgba(230, 0, 0, .7)' }, //red
  { code: 'popFr2', color: 'rgba(230, 104, 0, .7)' }, //orange
  { code: 'popFr1', color: 'rgba(245, 203, 64, .7)' }, //ocra
];

// // ORIGINAL
// const riskColor = [
//   { code: 'popFr4', color: '#730000' }, //dark red
//   { code: 'popFr3', color: '#e60000' }, //red
//   { code: 'popFr2', color: '#e66800' }, //orange
//   { code: 'popFr1', color: '#f5cb40' }, //ocra
//   { code: 'popFrAA', color: '#ffff73' }, //yellow
//   { code: 'popFrP3p4', color: '#666a6b' }, //dark grey
//   { code: 'popIdr3', color: '#730000' }, //dark red
//   { code: 'popIdr2', color: '#e60000' }, //red
//   { code: 'popIdr1', color: '#e66800' }, //orange
// ];

// const colorsIsvm = [
//   { code: 'popFr4', color: '#730000' }, //dark red
//   { code: 'popFr3', color: '#e60000' }, //red
//   { code: 'popFr2', color: '#e66800' }, //orange
//   { code: 'popFr1', color: '#f5cb40' }, //ocra
// ];

const tableIsvm = {
  header: ['level', 'vulnerability'],
  table: {
    popFr4: {
      min: 100.3,
      max: 119.64,
    },
    popFr3: {
      min: 98.66,
      max: 100.29,
    },
    popFr2: {
      min: 97.28,
      max: 98.65,
    },
    popFr1: {
      min: 91.12,
      max: 97.27,
    },
  },
};

const RiskDetails = ({ currentProject }) => {
  const [translations, setTranslations] = useState([]);
  const [project, setProject] = useState({});
  //const [indicators, setIndicators] = useState([]);
  //console.log('project.ispra.flood', project.ispra?.flood);

  useEffect(() => {
    getTranslations().then((retrievedTranslation) => {
      setTranslations(retrievedTranslation);
    });
  }, []);

  useEffect(() => {
    setProject(currentProject);

    let tempIndicators = [];

    if (currentProject.isvm) {
      colorsIsvm.map((el) => {
        if (currentProject.isvm >= el.valueMin && currentProject.isvm <= el.valueMax) {
          return tempIndicators.push(
            <Button
              key={el.color}
              className='button-group-rating showRatingESG rounded'
              size='lg'
              disabled>
              <span id={el.color}>
                {Number(currentProject.isvm).toLocaleString('it-IT', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
                <div className='arrow-down'></div>
              </span>
            </Button>
          );
        } else {
          return tempIndicators.push(
            <Button
              key={el.color}
              id={el.color}
              variant='outline-dark'
              className='button-group-rating'
              size='lg'
              disabled>
              {/* invisible */}
            </Button>
          );
        }
      });
    }

    //setIndicators(tempIndicators);
  }, [currentProject]);

  const buildRiskTable = (data, valueIsvm) => {
    const colHeader = [<th key='th-empty'></th>];
    colHeader.push(
      data.header.map((headerName) => (
        <th key={headerName} className='text-right cell-width-bm text-capitalize'>
          {translations.filter((trans) => trans.code === headerName).map((trans) => trans.text)}
        </th>
      ))
    );
    console.log(colHeader);

    // const orderedNames = Object.keys(data.table).sort((nameElA, nameElB) => {
    //   console.log('LocalCompare:', nameElA, nameElB);
    //   return nameElA.includes('AA') || nameElA.includes('P3p4')
    //     ? null
    //     : -1 * nameElA.localeCompare(nameElB);
    // });

    const orderedNames = Object.keys(data.table).sort((nameElA, nameElB) => {
      // console.log('LocalCompare:', nameElA, nameElB);
      if (nameElA.includes('P3p4')) {
        return null;
      } else if (nameElA.includes('AA')) {
        return 1;
      } else if (nameElB.includes('AA')) {
        return -1;
      } else {
        return -1 * nameElA.localeCompare(nameElB);
      }
    });
    const arrayColors = valueIsvm ? colorsIsvm : riskColor;

    return (
      <table className='bm-table'>
        <thead>
          <tr>{colHeader}</tr>
        </thead>
        <tbody>
          {orderedNames
            .filter((nameEl) => nameEl !== 'popFrP3p4')
            .map((nameEl) => {
              const bgColor = arrayColors.filter((el) => el.code === nameEl).map((el) => el.color);

              return (
                <tr key={nameEl}>
                  <td style={{ width: '1rem', backgroundColor: bgColor }}></td>
                  <td className='f-600' /* style={{whiteSpace: 'nowrap'}} */>
                    {valueIsvm
                      ? `${data.table[nameEl].min} - ${data.table[nameEl].max}`
                      : translations
                          .filter((trans) => trans.code === nameEl)
                          .map((trans) => trans.text)}
                  </td>
                  <td className='text-right'>
                    {valueIsvm ? (
                      valueIsvm > data.table[nameEl].min && valueIsvm < data.table[nameEl].max ? (
                        <span className='value-isvm' style={{ backgroundColor: bgColor }}>
                          {valueIsvm.toLocaleString('it-IT', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      ) : null
                    ) : (
                      Object.keys(data.table[nameEl]).map((key, index) => {
                        return (
                          <span key={key + index} className='ml-2' style={{ whiteSpace: 'nowrap' }}>
                            {valueIsvm
                              ? valueIsvm > data.table[nameEl].min &&
                                valueIsvm < data.table[nameEl].max
                                ? valueIsvm
                                : null
                              : Object.keys(data.table[nameEl]).length === index + 1
                              ? ` (${data.table[nameEl][key].toLocaleString('it-IT', {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2,
                                })}%)`
                              : data.table[nameEl][key].toLocaleString('it-IT', {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2,
                                })}
                          </span>
                        );
                      })
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <Row className='mb-5' style={{ marginTop: '3rem' }}>
        <Col className='col-4'>
          <Card className='cards-utente h-100 d-none d-lg-block pb-0 mt-0'>
            <Card.Body style={{ height: '100%' }}>
              <Ico3 className='logo-card ico-box-blue' />

              <Card.Title className='margin-title-box text-uppercase pt-3'>
                {translations.filter((trans) => trans.code === 'ICS056').map((trans) => trans.text)}
                {translations
                  .filter((trans) => trans.code === 'ICS056')
                  .map((trans) => trans.description)
                  .join() ? (
                  <OverlayTrigger
                    placement='right'
                    delay={{ show: 200, hide: 400 }}
                    overlay={
                      <Tooltip id='button-tooltip'>
                        {translations
                          .filter((trans) => trans.code === 'ICS056')
                          .map((trans) => trans.description)}
                      </Tooltip>
                    }>
                    <TooltipInfoIcon className='tooltip-ico mb-1' />
                  </OverlayTrigger>
                ) : null}
              </Card.Title>

              <Row className='pl-3 pr-4 mt-3 flex-column mb-3 padding-table'>
                <Col className=''>{project.isvm && buildRiskTable(tableIsvm, project.isvm)}</Col>
              </Row>

                  <Row className="risk-height-row no-to-head">
                     <Col>
                        <div className='sources'>
                           Fonte dati: <a href="https://www.istat.it/" target="_blank" rel="noreferrer noopener">ISTAT</a>
                        </div>
                     </Col>
                  </Row>
               </Card.Body>
            </Card>
         </Col>

        <Col className='col-4'>
          <Card className='cards-utente h-100 d-none d-lg-block pb-0 mt-0'>
            <Card.Body style={{ height: '100%' }}>
              <Ico100 className='logo-card ico-box-blue' />

              <Card.Title className='margin-title-box text-uppercase pt-3'>
                {translations
                  .filter((trans) => trans.code === 'ICS054')
                  .map((trans) => {
                    console.log(trans.text);
                    return trans.text;
                  })}
                {translations
                  .filter((trans) => trans.code === 'ICS054')
                  .map((trans) => trans.description)
                  .join() ? (
                  <OverlayTrigger
                    placement='right'
                    delay={{ show: 200, hide: 400 }}
                    overlay={
                      <Tooltip id='button-tooltip'>
                        {translations
                          .filter((trans) => trans.code === 'ICS054')
                          .map((trans) => trans.description)}
                      </Tooltip>
                    }>
                    <TooltipInfoIcon className='tooltip-ico mb-1' />
                  </OverlayTrigger>
                ) : null}
              </Card.Title>

              <Row className='pl-3 pr-4 mt-3 flex-column mb-3 padding-table'>
                {console.log(project.ispra && project.ispra.slide)}
                <Col className='mb-5'>{project.ispra && buildRiskTable(project.ispra.slide)}</Col>
              </Row>

                  <Row className="risk-height-row no-to-head">
                     <Col>
                        <div className='sources'>
                           Fonte dati: <a href="https://www.isprambiente.gov.it/it" target="_blank" rel="noreferrer noopener">ISPRA</a>
                        </div>
                     </Col>
                  </Row>
               </Card.Body>
            </Card>
         </Col>

        <Col className='col-4'>
          <Card className='cards-utente h-100 d-none d-lg-block pb-0 mt-0'>
            <Card.Body style={{ height: '100%' }}>
              <Ico99 className='logo-card ico-box-blue' />

              <Card.Title className='margin-title-box text-uppercase pt-3'>
                {translations.filter((trans) => trans.code === 'ICS055').map((trans) => trans.text)}
                {translations
                  .filter((trans) => trans.code === 'ICS055')
                  .map((trans) => trans.description)
                  .join() ? (
                  <OverlayTrigger
                    placement='right'
                    delay={{ show: 200, hide: 400 }}
                    overlay={
                      <Tooltip id='button-tooltip'>
                        {translations
                          .filter((trans) => trans.code === 'ICS055')
                          .map((trans) => trans.description)}
                      </Tooltip>
                    }>
                    <TooltipInfoIcon className='tooltip-ico mb-1' />
                  </OverlayTrigger>
                ) : null}
              </Card.Title>

              <Row className='pl-3 pr-4 mt-3 flex-column mb-3 padding-table'>
                <Col className=''>{project.ispra && buildRiskTable(project.ispra.flood)}</Col>
              </Row>

                  <Row className="risk-height-row no-to-head">
                     <Col>
                        <div className='sources'>
                           Fonte dati: <a href="https://www.isprambiente.gov.it/it" target="_blank" rel="noreferrer noopener">ISPRA</a>
                        </div>
                     </Col>
                  </Row>
               </Card.Body>
            </Card>
         </Col>
      </Row>
    </>
  );
};

export default RiskDetails;
