import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import {
  ArrowDownUp,
  // IcoSetup,
  // IcoGeneral,
  // IcoTechnical,
  // IcoEconomic,
  // IcoAdditional,
} from '@icons';
import { Ico44, Ico47, Ico89, Ico90, Ico91 } from '@icons/new';
import { Link } from 'react-router-dom';
import { Trans } from '@lib/i18n';
import { useParams, useLocation } from 'react-router-dom';
//import { getUser } from '@lib/api';

const ImpactAnalysis = () => {
  const location = useLocation();
  const { pathname } = location;
  const { idScenario = null } = useParams();
  const [subactiveId, subSetActiveId] = useState('ia-0');
  const { scenario = null } = location?.state || {};
  /* const spanDisabledStyle = {
    backgroundColor: 'white',
    opacity: '0.2',
    cursor: 'default',
  }; */

  //console.log('IA Scenario location', location);

  //const [user, setUser] = useState(null);
  useEffect(() => {
    /* getUser()
      .then(({ user }) => {
        setUser(user); 
        localStorage.setItem('user', JSON.stringify(user)); 
        }) */
  }, []);

  const subToggleActive = (id) => {
    if (subactiveId === id) {
      subSetActiveId(null);
    } else {
      subSetActiveId(id);
    }
  };
  return (
    <ul className="menu-container">
      <li className="menu-item">
				
        <span className="menu-link">
          <div className="nav-ico">
            <Link to="/scenari">
              <Ico89 />
            </Link>
          </div>
          <div className="nav-text">
            <Link to="/scenari">
              <Trans code="A0036" />
            </Link>
          </div>
        </span>
      </li>
      <li className="menu-item">
        <Accordion className="sub-accordion" defaultActiveKey="ia-1">
          {/* <Card className={subactiveId === 'ia-0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="ia-0" onClick={() => subToggleActive('ia-0')} className="panel-toggle" block>
                        <div className="nav-ico"><IcoSetup/></div>
                        <div className="nav-text">Setup</div>
                        <div className="arrow-ico"><ArrowDownUp/></div>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="ia-0">
                      <Card.Body>
                        <ul className="menu-container">
                          <li className="menu-item">
                            <a className="menu-link">
                              <div className="nav-ico"><IcoGeneral/></div>
                              <div className="nav-text">General</div>
                            </a>
                          </li>
                          <li className="menu-item">
                            <a className="menu-link">
                              <div className="nav-ico"><IcoTechnical/></div>
                              <div className="nav-text">Technical</div>
                            </a>
                          </li>
                          <li className="menu-item">
                            <a className="menu-link">
                              <div className="nav-ico"><IcoEconomic/></div>
                              <div className="nav-text">Economic</div>
                            </a>
                          </li>
                          <li className="menu-item">
                            <a className="menu-link">
                              <div className="nav-ico"><IcoAdditional/></div>
                              <div className="nav-text">Additional</div>
                            </a>
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card> */}
          {idScenario && (
            <Card
              className={
                subactiveId === 'ia-1'
                  ? 'panel-wrap active-panel'
                  : 'panel-wrap'
              }
            >
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="ia-1"
                  onClick={() => subToggleActive('ia-1')}
                  className="panel-toggle"
                  block
                >
                  <div className="nav-ico">
                    <Ico44 />
                  </div>
                  <div className="nav-text">
                    <Trans code="C00498" />
                  </div>
                  <div className="arrow-ico">
                    <ArrowDownUp />
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="ia-1">
                <Card.Body>
                  <ul className="menu-container">
                    <li className="menu-item">
                      <span
                        className={
                          pathname.match(/.+dashboard\/?$/gm)
                            ? 'menu-link active'
                            : 'menu-link'
                        }
                      >
                        <div className="nav-ico">
                          <Link
                            to={{
                              pathname: `/scenari/${idScenario}/dashboard`,
                              state: { scenario },
                            }}
                          >
                            <Ico47 />
                          </Link>
                        </div>
                        <div className="nav-text">
                          <Link
                            to={{
                              pathname: `/scenari/${idScenario}/dashboard`,
                              state: { scenario },
                            }}
                          >
                            <Trans code="F0058" />
                          </Link>
                        </div>
                      </span>
                    </li>
                    <li className="menu-item">
                      <span
                        className={
                          pathname.match(/.+sdg\/?$/gm)
                            ? 'menu-link active'
                            : 'menu-link'
                        }
                      >
                        {' '}
                        {/*style={( scenario && scenario.publish === '1' ) ? {} : spanDisabledStyle }*/}
                        <div className="nav-ico">
                          <Link
                            to={{
                              pathname: `/scenari/${idScenario}/sdg`,
                              state: { scenario },
                            }}
                          >
                            <Ico90 />
                          </Link>
                        </div>
                        <div className="nav-text">
                          <Link
                            to={{
                              pathname: `/scenari/${idScenario}/sdg`,
                              state: { scenario },
                            }}
                          >
                            SDG
                          </Link>
                        </div>
                      </span>
                    </li>
                    <li className="menu-item">
                      <span
                        className={
                          pathname.match(/.+comparators\/?$/gm)
                            ? 'menu-link _active'
                            : 'menu-link'
                        }
                      >
                        {' '}
                        {/*style={( scenario && scenario.publish === '1' )  ? {} : spanDisabledStyle } */}
                        <div className="nav-ico">
                          <Link
                            to={{
                              pathname: `/scenari/${idScenario}/comparators`,
                              state: { scenario },
                            }}
                          >
                            <Ico91 />
                          </Link>
                        </div>
                        <div className="nav-text">
                          <Link
                            to={{
                              pathname: `/scenari/${idScenario}/comparators`,
                              state: { scenario },
                            }}
                          >
                            <Trans code="A0222" />
                          </Link>
                        </div>
                      </span>
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )}
        </Accordion>
      </li>
    </ul>
  );
};

export default ImpactAnalysis;
