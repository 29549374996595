import React, { useState, useEffect, /* isValidElement */ } from 'react';
import {
  useParams,
  /* BrowserRouter as Router, */
  // Link,
  // useLocation,
} from 'react-router-dom';
//import { Trans } from '@lib/i18n';

import {
  getTranslations,
  getProject,
} from '@lib/api';
import ValutazioneHeader from 'components/valutazione/ValutazioneHeader';
import ProjectHeader from '@components/project/ProjectHeader';
import {
  ValutazioneGeneral,
  ValutazioneESG,
  ValutazioneSROI,
  ValutazioneSROIAUD,
} from '@components/valutazione';

const Valutazione = (props) => {
  const [project, setProject] = useState(null);
  const { projectId = null } = useParams();
  const [translations, setTranslations] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  //console.log('props.location.pathname', props.location);
  
  const activeStep =
    props.location.pathname.indexOf('esg') > 1 ? 'esg': props.location.pathname.indexOf('sroi') > 1 ? 'sroi' : 'general';

  useEffect(() => {
    getTranslations().then((retrievedTranslation) => {
      setTranslations(retrievedTranslation);
    });

    if (projectId) {
      getProject(projectId).then((retrievedProject) => {
         let tempQuestions = [...retrievedProject.questions];
         tempQuestions = tempQuestions.map(el => ({ ...el, dependsOnAnswers: el.dependsOnAnswers ? el.dependsOnAnswers.split(',').map(el => Number(el)) : [] }));
         retrievedProject.questions = tempQuestions;

         setProject(retrievedProject);
      });
    } else {
      setProject({});
    }
  }, [projectId]);

  if (!project) return null;

  return (
    <>
      <div id="valutazione" className="scenari megaContent">
        <div className="container-fluid">
          <ProjectHeader project={project} translations={translations} />

          <ValutazioneHeader
            activeStep={activeStep}
            projectId={parseInt(projectId)}
            projectStatus={project.status}
            sectorCode={project.sectorCode}
            proponentCode={project.proponentCode}
            translations={translations}
            isUpdated={isUpdated}
            setIsUpdated={setIsUpdated}
            clientId={project.clientId}
            requestId={project.requestId}
            updatedAt={project.updatedAt}
          />

          {activeStep === 'general' && (
            <ValutazioneGeneral currentProject={project} translations={translations} />
          )}
          {activeStep === 'esg' && <ValutazioneESG currentProject={project} translations={translations} />}
          {activeStep === 'sroi' && project.proponentCode === "AUD" && (
            <ValutazioneSROIAUD currentProject={project} translations={translations} isUpdated={isUpdated} setIsUpdated={setIsUpdated} />
          )}
          {activeStep === 'sroi' && project.proponentCode !== "AUD" && (
            <ValutazioneSROI currentProject={project} translations={translations} />
          )}
        </div>
      </div>
    </>
  );
};
export default Valutazione;
