import React, { Component } from 'react';

class Ico96 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg
        id="ico-96"
        viewBox="0 0 117.62 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        height={this.props.height}
        width={this.props.width}
      >
        <path
          d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z"
          className="esagono"
        />
        <g transform="scale(0.45)" style={{transformOrigin: 'center'}}>
          <path
            d="M116.6 62.86H73.98c-.66 0-1.19.53-1.19 1.19v17.83c0 .31.12.62.35.84c.22.22.52.35.84.35l13.81-.02C86.74 91.44 77.24 98.3 66.3 98.3C49.43 98.3 39 82.8 39 68.43c0-14.13 10.21-28.75 27.3-28.75c7.41 0 16.95 4.43 21.88 8.71c.24.21.55.3.86.29c.32-.02.62-.17.82-.41l15.85-18.29a1.2 1.2 0 0 0-.09-1.66c-10.14-9.4-23.74-14.37-39.32-14.37c-32.5 0-56.09 22.91-56.09 54.47c0 31.57 23.59 54.48 56.09 54.48c49.01 0 51.49-49.04 51.49-58.87c0-.64-.53-1.17-1.19-1.17z"
            className="ico"
          />
        </g>
      </svg>
    );
  }
}
export default Ico96;
