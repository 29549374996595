import React, { useRef } from 'react';
import { Row, Col, Button, Nav, Navbar } from 'react-bootstrap';
import { downloadPDF } from 'components/form/downloadPDF';
import { Externalyticslogo, CreditoSportivoLogo } from 'loghi';

const Thanks = () => {
   const translations = JSON.parse(localStorage.getItem('translations'));
   const printRef = useRef();
   const params = new URLSearchParams(window.location.search);
   const ndg = params.get('ndg'); 
   const rapporto = params.get('rapporto'); 
   const date = params.get('date'); 
   const hours = params.get('hours'); 

   const printCertificate = () => {
      //const div = document.getElementById('container-thanks');
      //div.style.margin = '1rem 0.5rem';

      downloadPDF(printRef.current, `ics_certificato_di_completamento_ndg${ndg}_nr${rapporto}`).then(() => {
         //div.style.margin = 'inherit';
      });
   };

   return (
      <>
         {false &&
         <div>
            <header id="site-header">
               <Navbar expand="lg" variant="light">
                  <Nav className="mr-auto left-side text-center" style={{justifyContent: 'space-between'}}>
                     <Navbar.Brand>
                        <CreditoSportivoLogo className="icslogo" />
                     </Navbar.Brand>
                     <div className='powered-by-header'>
                        <small>Powered by</small>
                        <Externalyticslogo className="externalyticslogo" />
                     </div>
                  </Nav>
               </Navbar>
            </header>
            <Row className="row-thanks">
               <Col className="first-col-thanks">
                  <div className="text-thanks" style={{marginBottom: '3rem'}}>
                     <p className='no-to-head'>
                        {translations.filter(trans => trans.code === 'ICS101').map(trans => trans.text)}&nbsp;{ndg}&nbsp;
                        {translations.filter(trans => trans.code === 'ICS102').map(trans => trans.text)}&nbsp;{rapporto}&nbsp;
                     </p>
                     <p>{translations.filter(trans => trans.code === 'ICS103').map(trans => trans.text)}&nbsp;{date}&nbsp;
                        {translations.filter(trans => trans.code === 'ICS104').map(trans => trans.text)}&nbsp;{hours}
                     </p>
                     {/* <h1 className='text-uppercase'>{translations.filter(trans => trans.code === 'ICS098').map(trans => trans.text)}</h1>
                     <span>{translations.filter(trans => trans.code === 'ICS099').map(trans => trans.text)}</span> */}
                  </div>
               </Col>
               <Col className="second-col-thanks">
                  <img
                     src="/img/thanks-assessment.jpg"
                     alt="thanks-img"
                     className="img-thanks"
                  />
               </Col>
            </Row>
         </div>
         }

         <div className="parent-thanks">
            <div id="container-thanks" ref={printRef}>
               <div className="logo-thanks">
                  <CreditoSportivoLogo className="icslogo" />
               </div>

               <Row className="flex-nowrap justify-content-center mt-5">
                  <div className="marquee">
                     {translations.filter(trans => trans.code === 'ICS106').map(trans => trans.text)}
                  </div>
               </Row>
               <Row className="flex-nowrap align-items-center justify-content-center mt-2">
                  <Col className="first-col-cert col-6">
                     <div className="content-thanks">
                        <div className="assignment">
                           <p className='no-to-head'>
                              {translations.filter(trans => trans.code === 'ICS101').map(trans => trans.text)}&nbsp;{ndg}&nbsp;
                           </p>
                           <p className='no-to-head'>
                              {translations.filter(trans => trans.code === 'ICS102').map(trans => trans.text)}&nbsp;{rapporto}&nbsp;
                           </p> 

                           <p>
                              {translations.filter(trans => trans.code === 'ICS103').map(trans => trans.text)}&nbsp;</p>
                           <p>   
                              {date}&nbsp;
                              {translations.filter(trans => trans.code === 'ICS104').map(trans => trans.text)}&nbsp;{hours}
                           </p>
                        </div>
                     </div>
                  </Col>

                  <Col className="second-col-cert col-4">
                     <img
                        src="/img/thanks-assessment.jpg"
                        alt="thanks-img"
                        className="img-thanks"
                        style={{maxHeight: '16rem'}}
                     />
                  </Col>
               </Row>  
            </div>
         </div>

         <Row id="certificate-button" className="justify-content-center">
            <Button variant='primary' onClick={() => printCertificate()}>
               {translations.filter(trans => trans.code === 'ICS097').map(trans => trans.text)}
            </Button>
         </Row>
      </>
   );
};

export default Thanks;