import React from 'react';
import AdminAccess from 'components/adminAccess';

const AdminLoginPage = () => {
  return (
    <div id="admin-login">
      <AdminAccess />
    </div>
  );
};

export default AdminLoginPage;