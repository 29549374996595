import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
//import { Trans } from '@lib/i18n';
import { getAUDTypes, getAUDGenres, putAUDProject } from '@lib/api';
import { TooltipInfoIcon } from '@icons';
import { InfoToast, SuccessToast, DangerToast } from '@components/GlobalToastList';
import { InputNumber } from 'primereact';

const AUDProject = ({
  currentAUDProject,
  translations,
  projectStatus,
  handleChangesInAudProject,
  handleDeleteInAudProject,
}) => {
  const [AUDproject, setAUDProject] = useState(currentAUDProject);
  // const [AUDproject, setAUDProject] = useState({
  //   ...currentAUDProject,
  //   aboveTheLine: 12000000,
  //   belowTheLine: 3000000,
  // });

  const [errors, setErrors] = useState({});

  const [optionsAUDTypes, setOptionsAUDTypes] = useState([]);
  const [optionsAUDGenres, setOptionsAUDGenres] = useState([]);

  const [selectedAUDType, setSelectedAUDType] = useState([]);
  const [selectedAUDGenre, setSelectedAUDGenre] = useState([]);

  const [MaxAIRCinema, setMaxAIRCinema] = useState(currentAUDProject.productionYearStart + 5);
  const [MaxAIRTV, setMaxAIRTV] = useState(currentAUDProject.productionYearStart + 5);
  const [MaxAIRPlat, setMaxAIRPlat] = useState(currentAUDProject.productionYearStart + 5);

  const [isDisabledCinemaYear, setIsDisabledCinemaYear] = useState(
    !AUDproject.distributionContractCinema
  );
  const [isDisabledTelevisionYear, setIsDisabledTelevisionYear] = useState(
    !AUDproject.distributionContractTelevision
  );
  const [isDisabledPlatformYear, setIsDisabledPlatformYear] = useState(
    !AUDproject.distributionContractPlatforms
  );
  //console.log('AUDproject', AUDproject);

  useEffect(() => {
    getAUDTypes().then((retrieved) => {
      setOptionsAUDTypes(
        retrieved.response.map((entry) => ({
          value: entry.code,
          label: entry.text,
        }))
      );
    });

    getAUDGenres().then((retrieved) => {
      setOptionsAUDGenres(
        retrieved.response.map((entry) => ({
          value: entry.code,
          label: entry.text,
        }))
      );
    });
  }, []);

  useEffect(() => {
    setSelectedAUDType(
      optionsAUDTypes
        .filter((option) => option.value === AUDproject.projectType)
        .map((filtered) => ({
          value: filtered.value,
          label: filtered.label,
        }))
    );

    setSelectedAUDGenre(
      optionsAUDGenres
        .filter((option) => option.value === AUDproject.productionGenre)
        .map((filtered) => ({
          value: filtered.value,
          label: filtered.label,
        }))
    );

    setMaxAIRCinema(AUDproject.productionYearStart + 5);
    setMaxAIRPlat(AUDproject.productionYearStart + 5);
    setMaxAIRTV(AUDproject.productionYearStart + 5);

    //report changes to parent
    handleChangesInAudProject(AUDproject);

    let errors = {};
    //NAME CANNO BE NULL or EMPTY
    errors.name = !AUDproject.name || AUDproject.name.trim() === '' ? true : false;

    //L'ANNO DI USCITA IN TV, CINEMA o PIATTAFORME non può essere più di 5 anni dopo l'inizio produzione
    errors.onaircinemaYear =
      parseInt(AUDproject.onaircinemaYear) > parseInt(AUDproject.productionYearStart) + 5
        ? true
        : false;
    errors.onairtelevisionYear =
      parseInt(AUDproject.onairtelevisionYear) > parseInt(AUDproject.productionYearStart) + 5
        ? true
        : false;
    errors.onairplatformYear =
      parseInt(AUDproject.onairplatformYear) > parseInt(AUDproject.productionYearStart) + 5
        ? true
        : false;

    //I costi di produzione non possono essere negativi
    errors.aboveTheLine = AUDproject.aboveTheLine < 0 ? true : false;
    errors.belowTheLine = AUDproject.belowTheLine < 0 ? true : false;
    errors.otherCosts = AUDproject.otherCosts < 0 ? true : false;

    //In assenza di un contratto di distribuzione i cinema contrattualizzati devono essere 0
    errors.contractCinemas =
      AUDproject.contractCinemas > 0 && !AUDproject.distributionContractCinema ? true : false;

    //In assenza di cinema contrattualizati i numero di spettatori deve essere zero
    errors.estimatedCinemaUsers =
      AUDproject.estimatedCinemaUsers > 0 && AUDproject.contractCinemas === 0 ? true : false;

    setErrors(errors);

    //console.log("errors", errors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAUDProject, AUDproject, optionsAUDTypes, optionsAUDGenres]);

  const onDelete = () => {
    console.log('delete me', AUDproject.id);
    handleDeleteInAudProject(AUDproject);
  };

  const onSave = () => {
    InfoToast('Salvataggio...', 'Stiamo apportando le modifiche');

    console.log('onsave', AUDproject.id, AUDproject);
    putAUDProject(AUDproject.id, AUDproject).then((result) => {
      if (result.code === 200) {
        SuccessToast('Operazione conclusa', 'Progetto salvato correttamente');
      } else {
        DangerToast('Attenzione', 'Errore nel salvataggio del progetto');
      }
    });
  };

  const onChange = (e, section, fieldName, setSelect) => {
    console.log('e test:', e);
    console.log('section test:', section);
    console.log('fieldName test:', fieldName);

    /*setAUDProject(() => ({
        ...AUDproject,
        [fieldName]: e.target.value
      }));*/

    if (section === 'options') {
      setAUDProject(() => ({
        ...AUDproject,
        [fieldName]: e.value,
      }));

      setSelect({
        value: e.value,
        label: e.label,
      });
    } else if (section === 'inputNumber') {
      if (fieldName === 'contractCinemas') {
         //Numero di sale contrattualizzate
         let cc = e.value;
         let estimatedCinemaUsers = cc * 37 * 3 * 42;
         setAUDProject(() => ({
           ...AUDproject,
           contractCinemas: cc,
           estimatedCinemaUsers: estimatedCinemaUsers,
         }));
      } else if (fieldName === 'expenses') {
         //sopra la linea, sotto la linea, altri costi
         setAUDProject(() => ({
            ...AUDproject,
            [e.originalEvent.target.name]: Number(e.value ?? 0),
         }));
      }
    } /* else if (section === 'aboveTheLine') {
      //Cositi di produzione, altri costi
      let pcValue =
        e.target.value === undefined ||
        e.target.value === null ||
        e.target.value === '' ||
        e.target.value < 0
          ? 0
          : e.target.value;

      //let ocValue = pcValue*0.02

      setAUDProject(() => ({
        ...AUDproject,
        aboveTheLine: +pcValue,
      }));
    } else if (section === 'belowTheLine') {
      //Cositi di produzione, altri costi
      let pcValue =
        e.target.value === undefined ||
        e.target.value === null ||
        e.target.value === '' ||
        e.target.value < 0
          ? 0
          : e.target.value;

      //let ocValue = pcValue*0.02

      setAUDProject(() => ({
        ...AUDproject,
        belowTheLine: +pcValue,
      }));
    } else if (section === 'productionFee') {
      //Cositi di produzione, altri costi
      let pcValue =
        e.target.value === undefined ||
        e.target.value === null ||
        e.target.value === '' ||
        e.target.value < 0
          ? 0
          : e.target.value;

      //let ocValue = pcValue*0.02

      setAUDProject(() => ({
        ...AUDproject,
        otherCosts: +pcValue,
      }));
    }*/ else if (section === 'contracts') {
      let cc =
        fieldName === 'distributionContractCinema' && e.target.checked === false
          ? 0
          : AUDproject.contractCinemas;
      let estimatedCinemaUsers = cc * 37 * 3 * 42;

      let tempAUDproject = AUDproject;

      tempAUDproject[fieldName] = e.target.checked;
      tempAUDproject.contractCinemas = cc;
      tempAUDproject.estimatedCinemaUsers = estimatedCinemaUsers;

      switch (fieldName) {
        case 'distributionContractCinema':
          if (e.target.checked) {
            setIsDisabledCinemaYear(false);
            tempAUDproject.distributionContractCinema = 1; //as true
          } else {
            setIsDisabledCinemaYear(true);
            tempAUDproject.distributionContractCinema = 0; //as false
            tempAUDproject.onaircinemaYear = 0; //quantity
          }
          break;
        case 'distributionContractTelevision':
          if (e.target.checked) {
            setIsDisabledTelevisionYear(false);
            tempAUDproject.distributionContractTelevision = 1; //as true
          } else {
            setIsDisabledTelevisionYear(true);
            tempAUDproject.distributionContractTelevision = 0; //as false
            tempAUDproject.onairtelevisionYear = 0; //quantity
          }
          break;
        case 'distributionContractPlatforms':
          if (e.target.checked) {
            setIsDisabledPlatformYear(false);
            tempAUDproject.distributionContractPlatforms = 1; //as true
          } else {
            setIsDisabledPlatformYear(true);
            tempAUDproject.distributionContractPlatforms = 0; //as false
            tempAUDproject.onairplatformYear = 0; //quantity
          }
          break;
        default:
          break;
      }

      setAUDProject(tempAUDproject);
    } /* else if (section === 'contractCinemas') {
      let cc = e.target.value;
      let estimatedCinemaUsers = cc * 37 * 3 * 42;
      setAUDProject(() => ({
        ...AUDproject,
        contractCinemas: cc,
        estimatedCinemaUsers: estimatedCinemaUsers,
      }));
    }  */else {
      //nome, Anno di produzione, durata di produzione, minutaggio
      let targetValue = e.target.type === 'number' ? Number(e.target.value) : e.target.value;

      setAUDProject(() => ({
        ...AUDproject,
        [fieldName]: targetValue,
      }));
    }

    //checkErrors();
  };

  // const checkErrors = () => {
  //    /**
  //     * name: "Nuovo Progetto Audiovisivo",
  //    projectType: "LONG",
  //    productionYearStart: 2023,
  //    productionDurationWeeks: 8,
  //    minsOperaDuration: 85,
  //    onaircinemaYear: 2024,
  //    onairtelevisionYear: 2025,
  //    onairplatformYear: 2025,
  //    productionCosts: 15000000,
  //    otherCosts: 30000,
  //    productionGenre: "COM",
  //    distributionContractCinema: true,
  //    contractCinemas: 200,
  //    distributionContractTelevision: true,
  //    distributionContractPlatforms: true,
  //    estimatedCinemaUsers: 932400
  //     */

  //    let errors = {};
  //    //NAME CANNO BE NULL or EMPTY
  //    errors.name = !AUDproject.name || AUDproject.name.trim() === "" ? true : false;

  //    //L'ANNO DI USCITA IN TV, CINEMA o PIATTAFORME non può essere più di 5 anni dopo l'inizio produzione
  //    errors.onaircinemaYear = parseInt(AUDproject.onaircinemaYear) > parseInt(AUDproject.productionYearStart) + 5 ? true : false
  //    errors.onairtelevisionYear = parseInt(AUDproject.onairtelevisionYear) > parseInt(AUDproject.productionYearStart) + 5 ? true : false
  //    errors.onairplatformYear = parseInt(AUDproject.onairplatformYear) > parseInt(AUDproject.productionYearStart) + 5 ? true : false

  //    //I costi di produzione non possono essere negativi
  //    errors.productionCosts = AUDproject.productionCosts < 0 ? true : false
  //    errors.otherCosts = AUDproject.otherCosts < 0 ? true : false

  //    //In assenza di un contratto di distribuzione i cinema contrattualizzati devono essere 0
  //    errors.contractCinemas = AUDproject.contractCinemas > 0 && !AUDproject.distributionContractCinema ? true : false

  //    //In assenza di cinema contrattualizati i numero di spettatori deve essere zero
  //    errors.estimatedCinemaUsers = AUDproject.estimatedCinemaUsers > 0 && AUDproject.contractCinemas === 0 ? true : false

  //    setErrors(errors)
  //    console.log("errors", errors)
  // }

  const renderTooltip = (props) => {
    let message = '';

    if (props.popper.state) {
      message = props.popper.state.options.testObj;
    }

    return (
      <Tooltip id='button-tooltip' {...props}>
        {message}
      </Tooltip>
    );
  };

  const inputName = (
    <>
      <Form.Group as={Col} controlId='name'>
        <Form.Label className='text-nowrap w-100 text-center'>
          {translations.filter((trans) => trans.code === 'ICS071').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS071')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          className='input-width text-center larger'
          type='text'
          size='lg'
          name='name'
          feedback='Il campo non può essere vuoto'
          isInvalid={errors.name}
          defaultValue={AUDproject.name}
          onChange={(e) => onChange(e, 'name', 'name')}
          disabled={projectStatus === 'SUBMITTED' ? true : false}
        />
      </Form.Group>
    </>
  );

  const inputAUDType = (
    <>
      <Form.Group as={Col} controlId='audTypeCode' className='col-3'>
        <Form.Label className='text-nowrap w-100 text-center'>
          {translations.filter((trans) => trans.code === 'ICS072').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS072')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Select
          className='input-width text-center middle-select'
          classNamePrefix='center-text' //aggiunge prefisso alle classi dei sottocomponenti della select per custom styles
          name='audTypeCode'
          placeholder='Seleziona...'
          value={selectedAUDType}
          options={optionsAUDTypes}
          onChange={(e) => onChange(e, 'options', 'projectType', setSelectedAUDType)}
          isDisabled={projectStatus === 'SUBMITTED' ? true : false}
        />
      </Form.Group>
    </>
  );

  const inputAUDGenre = (
    <>
      <Form.Group as={Col} controlId='audGenreCode'>
        <Form.Label className='text-nowrap w-100 text-center'>
          {translations.filter((trans) => trans.code === 'ICS073').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS073')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Select
          className='input-width text-center larger'
          classNamePrefix='center-text' //aggiunge prefisso alle classi dei sottocomponenti della select per custom styles
          name='audTypeCode'
          placeholder='Seleziona...'
          value={selectedAUDGenre}
          options={optionsAUDGenres}
          onChange={(e) => onChange(e, 'options', 'productionGenre', setSelectedAUDGenre)}
          isDisabled={projectStatus === 'SUBMITTED' ? true : false}
        />
      </Form.Group>
    </>
  );

  const inputAboveTheLine = (
    <>
      <Form.Group as={Col} controlId='aboveTheLine'>
        <Form.Label className='text-nowrap w-100 text-center'>
          {translations.filter((trans) => trans.code === 'ICS074').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS074')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <div className="input-width text-center larger">
         <InputNumber 
            name='aboveTheLine'
            value={AUDproject.aboveTheLine} 
            onChange={(e) => onChange(e, 'inputNumber', 'expenses')}
            mode="currency"
            minFractionDigits={0} 
            locale={`${localStorage.getItem('userLanguage').toLowerCase()}-${localStorage.getItem('userLanguage')}`}
            currency="EUR"
            min={0}

            disabled={projectStatus === 'SUBMITTED' ? true : false}
            className={`lg t-center ${errors.aboveTheLine ? 'p-invalid' : ''}`}
         />
       </div>
      </Form.Group>
    </>
  );
  const inputBelowTheLine = (
    <>
      <Form.Group as={Col} controlId='belowTheLine' className='col-3'>
        <Form.Label className='text-nowrap w-100 text-center'>
          {translations.filter((trans) => trans.code === 'ICS094').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS094')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <div className="input-width text-center middle-select">
         <InputNumber 
            name='belowTheLine'
            value={AUDproject.belowTheLine} 
            onChange={(e) => onChange(e, 'inputNumber', 'expenses')}
            mode="currency"
            minFractionDigits={0} 
            locale={`${localStorage.getItem('userLanguage').toLowerCase()}-${localStorage.getItem('userLanguage')}`}
            currency="EUR"
            min={0}

            disabled={projectStatus === 'SUBMITTED' ? true : false}
            className={`lg t-center ${errors.belowTheLine ? 'p-invalid' : ''}`}
         />
       </div>
      </Form.Group>
    </>
  );

  const inputOtherCosts = (
    <>
      <Form.Group as={Col} controlId='otherCosts'>
        <Form.Label className='text-nowrap w-100 text-center'>
          {translations.filter((trans) => trans.code === 'ICS075').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS075')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <div className="input-width text-center larger">
         <InputNumber 
            name='otherCosts'
            value={AUDproject.otherCosts} 
            onChange={(e) => onChange(e, 'inputNumber', 'expenses')}
            mode="currency"
            minFractionDigits={0} 
            locale={`${localStorage.getItem('userLanguage').toLowerCase()}-${localStorage.getItem('userLanguage')}`}
            currency="EUR"

            disabled={projectStatus === 'SUBMITTED' ? true : false}
            className={`lg t-center ${errors.otherCosts ? 'p-invalid' : ''}`}
         />
       </div>
      </Form.Group>
    </>
  );

  const inputProductionYearStart = (
    <>
      <Form.Group as={Col} controlId='productionYearStart'>
        <Form.Label className='text-nowrap w-100 text-center'>
          {translations.filter((trans) => trans.code === 'ICS076').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS076')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          className='input-width text-center larger'
          type='number'
          size='lg'
          min={2022}
          max={2050}
          name='productionYearStart'
          isInvalid={errors.productionYearStart}
          value={AUDproject.productionYearStart}
          onChange={(e) => onChange(e, 'productionYearStart', 'productionYearStart')}
          disabled={projectStatus === 'SUBMITTED' ? true : false}
        />
      </Form.Group>
    </>
  );

  const onaircinemaYear = (
    <>
      <Form.Group as={Col} controlId='onaircinemaYear'>
        <Form.Label className='text-nowrap w-100 text-center'>
          {translations.filter((trans) => trans.code === 'ICS078').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS078')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          className='input-width text-center'
          type='number'
          size='lg'
          min={2022}
          max={MaxAIRCinema}
          name='onaircinemaYear'
          isInvalid={errors.onaircinemaYear}
          value={isDisabledCinemaYear ? '' : AUDproject.onaircinemaYear}
          onChange={(e) => onChange(e, 'onaircinemaYear', 'onaircinemaYear')}
          disabled={projectStatus === 'SUBMITTED' || isDisabledCinemaYear ? true : false}
        />
      </Form.Group>
    </>
  );

  const onairtelevisionYear = (
    <>
      <Form.Group as={Col} controlId='onairtelevisionYear' /* className="col-3" */>
        <Form.Label className='text-nowrap w-100 text-center'>
          {translations.filter((trans) => trans.code === 'ICS079').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS079')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          className='input-width text-center'
          type='number'
          size='lg'
          min={2022}
          max={MaxAIRTV}
          name='onairtelevisionYear'
          isInvalid={errors.onairtelevisionYear}
          value={isDisabledTelevisionYear ? '' : AUDproject.onairtelevisionYear}
          onChange={(e) => onChange(e, 'onairtelevisionYear', 'onairtelevisionYear')}
          disabled={projectStatus === 'SUBMITTED' || isDisabledTelevisionYear ? true : false}
        />
      </Form.Group>
    </>
  );

  const onairplatformYear = (
    <>
      <Form.Group as={Col} controlId='onairplatformYear'>
        <Form.Label className='text-nowrap w-100 text-center'>
          {translations.filter((trans) => trans.code === 'ICS080').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS080')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          className='input-width text-center'
          type='number'
          size='lg'
          min={2022}
          max={MaxAIRPlat}
          name='onairplatformYear'
          isInvalid={errors.onairplatformYear}
          value={isDisabledPlatformYear ? '' : AUDproject.onairplatformYear}
          onChange={(e) => onChange(e, 'onairplatformYear', 'onairplatformYear')}
          disabled={projectStatus === 'SUBMITTED' || isDisabledPlatformYear ? true : false}
        />
      </Form.Group>
    </>
  );

  const inputProductionDurationWeeks = (
    <>
      <Form.Group as={Col} controlId='productionDurationWeeks' className='col-3'>
        <Form.Label className='text-nowrap w-100 text-center'>
          {translations.filter((trans) => trans.code === 'ICS085').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS085')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          className='input-width text-center middle-select'
          type='number'
          size='lg'
          min={0}
          max={3000}
          name='productionDurationWeeks'
          value={AUDproject.productionDurationWeeks}
          isInvalid={errors.productionDurationWeeks}
          onChange={(e) => onChange(e, 'productionDurationWeeks', 'productionDurationWeeks')}
          disabled={projectStatus === 'SUBMITTED' ? true : false}
        />
      </Form.Group>
    </>
  );

  const inputMinsOperaDuration = (
    <>
      <Form.Group as={Col} controlId='minsOperaDuration'>
        <Form.Label className='text-nowrap w-100 text-center'>
          {translations.filter((trans) => trans.code === 'ICS077').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS077')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          className='input-width text-center larger'
          type='number'
          size='lg'
          min={0}
          name='minsOperaDuration'
          value={AUDproject.minsOperaDuration}
          onChange={(e) => onChange(e, 'minsOperaDuration', 'minsOperaDuration')}
          disabled={projectStatus === 'SUBMITTED' ? true : false}
        />
      </Form.Group>
    </>
  );

  /* const inputContracts = <>
      <Form.Group as={Col} controlId="contracts">
         <Row>
            <Col>
               <Form.Label className="text-nowrap w-100 text-center">
                  {translations.filter(trans => trans.code === 'ICS089').map(trans => trans.text)}
                  <OverlayTrigger
                     placement="top"
                     delay={{ show: 200, hide: 400 }}
                     overlay={renderTooltip}
                     popperConfig={{ testObj: translations.filter(trans => trans.code === 'ICS089').map(trans => trans.description) }}
                  >
                     <TooltipInfoIcon className="tooltip-ico" style={{ width: '1rem' }} />
                  </OverlayTrigger>
               </Form.Label>
            </Col>
         </Row>
         <Row style={{height: '3rem'}} className="justify-content-center">
            <Form.Check className="ml-4"
               inline
               label={translations.filter(trans => trans.code === 'ICS086').map(trans => trans.text)}
               name="group1"
               type="checkbox"
               id="inline-checkbox-1"
               onChange={(e) => onChange(e, 'contracts', 'distributionContractCinema')}
               checked={AUDproject.distributionContractCinema}
            />
            <Form.Check className="ml-4"
               inline
               label={translations.filter(trans => trans.code === 'ICS087').map(trans => trans.text)}
               name="group1"
               type="checkbox"
               id="inline-checkbox-2"
               onChange={(e) => onChange(e, 'contracts', 'distributionContractTelevision')}
               checked={AUDproject.distributionContractTelevision}

            />
            <Form.Check className="ml-4"
               inline
               label={translations.filter(trans => trans.code === 'ICS088').map(trans => trans.text)}
               type="checkbox"
               id="inline-checkbox-3"
               onChange={(e) => onChange(e, 'contracts', 'distributionContractPlatforms')}
               checked={AUDproject.distributionContractPlatforms}
            />
         </Row>
      </Form.Group>
   </>; */

  /* const inputContractCinemas = <>
      <Form.Group as={Col} controlId="contractCinemas" className="col-3">
         <Row>
            <Col>
               <Form.Label className="text-nowrap w-100 text-center">
                  {translations.filter(trans => trans.code === 'ICS084').map(trans => trans.text)}
                  <OverlayTrigger
                     placement="top"
                     delay={{ show: 200, hide: 400 }}
                     overlay={renderTooltip}
                     popperConfig={{ testObj: translations.filter(trans => trans.code === 'ICS084').map(trans => trans.description) }}
                  >
                     <TooltipInfoIcon className="tooltip-ico" style={{ width: '1rem' }} />
                  </OverlayTrigger>
               </Form.Label>
            </Col>
         </Row>
         <Row>
            <Col>
               <Form.Control
                  className="input-width text-center"
                  type="number"
                  size="lg"
                  min={0}
                  name="contractCinemas"
                  value={AUDproject.contractCinemas}
                  onChange={(e) => onChange(e, 'contractCinemas', 'contractCinemas')}
                  disabled={projectStatus === 'SUBMITTED' || !AUDproject.distributionContractCinema ? true : false}
               />
            </Col>
         </Row>
      </Form.Group>
   </>; */

  /* const inputEstimatedCinemaUsers = <>
      <Form.Group as={Col} controlId="estimatedCinemaUsers">
         <Row>
            <Col className=''>
               <Form.Label className="text-nowrap w-100 text-center">
                  {translations.filter(trans => trans.code === 'ICS093').map(trans => trans.text)}
                  <OverlayTrigger
                     placement="top"
                     delay={{ show: 200, hide: 400 }}
                     overlay={renderTooltip}
                     popperConfig={{ testObj: translations.filter(trans => trans.code === 'ICS093').map(trans => trans.description) }}
                  >
                     <TooltipInfoIcon className="tooltip-ico" style={{ width: '1rem' }} />
                  </OverlayTrigger>
               </Form.Label>
            </Col>
         </Row>
         <Row>
            <Col className=''>
               <Form.Control
                  className="input-width text-center"
                  type="number"
                  size="lg"
                  min={0}
                  name="contractCinemas"
                  value={AUDproject.estimatedCinemaUsers}
                  onChange={(e) => onChange(e, 'estimatedCinemaUsers', 'estimatedCinemaUsers')}
                  disabled={true}
               />
            </Col>
         </Row>
      </Form.Group>
   </>; */

  const radioAndOnAirSection = (
    <>
      <Form.Group as={Col} controlId='contracts' className='col-10 mb-0'>
        <Row>
          <Col className='text-center'>
            <Form.Label className='text-nowrap w-100 text-center mb-5'>
              {translations.filter((trans) => trans.code === 'ICS089').map((trans) => trans.text)}
              <OverlayTrigger
                placement='top'
                delay={{ show: 200, hide: 400 }}
                overlay={renderTooltip}
                popperConfig={{
                  testObj: translations
                    .filter((trans) => trans.code === 'ICS089')
                    .map((trans) => trans.description),
                }}>
                <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
              </OverlayTrigger>
            </Form.Label>
          </Col>
        </Row>
        <Row className='flex-nowrap mb-2'>
          <Col className='text-center col-4'>
            <Form.Check
              className='mb-3'
              inline
              label={translations
                .filter((trans) => trans.code === 'ICS087')
                .map((trans) => trans.text)}
              name='group1'
              type='checkbox'
              id='inline-checkbox-2'
              onChange={(e) => onChange(e, 'contracts', 'distributionContractTelevision')}
              checked={AUDproject.distributionContractTelevision}
            />
            {onairtelevisionYear}
          </Col>
          <Col className='text-center col-4'>
            <Form.Check
              className='mb-3'
              inline
              label={translations
                .filter((trans) => trans.code === 'ICS086')
                .map((trans) => trans.text)}
              name='group1'
              type='checkbox'
              id='inline-checkbox-1'
              onChange={(e) => onChange(e, 'contracts', 'distributionContractCinema')}
              checked={AUDproject.distributionContractCinema}
            />
            {onaircinemaYear}
          </Col>
          <Col className='text-center col-4'>
            <Form.Check
              className='mb-3'
              inline
              label={translations
                .filter((trans) => trans.code === 'ICS088')
                .map((trans) => trans.text)}
              type='checkbox'
              id='inline-checkbox-3'
              onChange={(e) => onChange(e, 'contracts', 'distributionContractPlatforms')}
              checked={AUDproject.distributionContractPlatforms}
            />
            {onairplatformYear}
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col></Col>
          <Col className='text-center'>
            <Form.Group controlId='contractCinemas' className='mb-0'>
              <Form.Label className='text-nowrap w-100 text-center'>
                {translations.filter((trans) => trans.code === 'ICS084').map((trans) => trans.text)}
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 200, hide: 400 }}
                  overlay={renderTooltip}
                  popperConfig={{
                    testObj: translations
                      .filter((trans) => trans.code === 'ICS084')
                      .map((trans) => trans.description),
                  }}>
                  <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                </OverlayTrigger>
              </Form.Label>
              <div className='input-width text-center'>
                  <InputNumber 
                     name='contractCinemas'
                     value={AUDproject.contractCinemas} 
                     onChange={(e) => onChange(e, 'inputNumber', 'contractCinemas')}
                     mode="decimal"
                     minFractionDigits={0} 
                     maxFractionDigits={0} 
                     locale={`${localStorage.getItem('userLanguage').toLowerCase()}-${localStorage.getItem('userLanguage')}`}
                     min={0}
                     disabled={
                        projectStatus === 'SUBMITTED' || !AUDproject.distributionContractCinema
                          ? true
                          : false
                      }
                     className="lg t-center"
                  />
               </div>
            </Form.Group>
          </Col>
          <Col className='text-center'>
            <Form.Group controlId='estimatedCinemaUsers' className='mb-0'>
              <Form.Label className='text-nowrap w-100 text-center'>
                {translations.filter((trans) => trans.code === 'ICS093').map((trans) => trans.text)}
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 200, hide: 400 }}
                  overlay={renderTooltip}
                  popperConfig={{
                    testObj: translations
                      .filter((trans) => trans.code === 'ICS093')
                      .map((trans) => trans.description),
                  }}>
                  <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                </OverlayTrigger>
              </Form.Label>
              <div className='input-width text-center'>
                  <InputNumber 
                     name='estimatedCinemaUsers'
                     value={AUDproject.estimatedCinemaUsers} 
                     onChange={(e) => onChange(e, 'inputNumber', 'estimatedCinemaUsers')}
                     mode="decimal"
                     minFractionDigits={0} 
                     maxFractionDigits={0} 
                     locale={`${localStorage.getItem('userLanguage').toLowerCase()}-${localStorage.getItem('userLanguage')}`}
                     min={0}
                     disabled={true}
                     className="lg t-center"
                  />
               </div>
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
      </Form.Group>
    </>
  );

  return (
    <>
      <div className='simple-box' style={{ marginTop: '40px' }}>
        <Row>
          <Col lg={12} className='form-login aud-project'>
            <h5 className='title-simple-box margin-title-box'>
              {translations.filter((trans) => trans.code === 'ICS070').map((trans) => trans.text)}
            </h5>
            <Row className='mb-3'>
              {inputName}
              {inputAUDType}
              {inputAUDGenre}
            </Row>
            <hr className='m-hr' />
            <Row className='mb-3'>
              {inputAboveTheLine}
              {inputBelowTheLine}
              {inputOtherCosts}
            </Row>
            <Row className='mb-3'>
              {inputProductionYearStart}
              {inputProductionDurationWeeks}
              {inputMinsOperaDuration}
            </Row>

            {/* <hr className="m-hr"/>
                  <Row className="mb-3">
                     {onaircinemaYear}
                     {onairtelevisionYear}
                     {onairplatformYear}
                  </Row>
                  <hr className="m-hr"/>
                  <Row className="mb-3">
                     {inputContracts}
                     {inputContractCinemas}
                     {inputEstimatedCinemaUsers}
                  </Row> */}

            <hr className='m-hr' />
            <Row className='mb-3 justify-content-center'>{radioAndOnAirSection}</Row>

            <Button
              variant='danger'
              className='assessment-button float-right ml-3'
              onClick={() => onDelete()}
              disabled={projectStatus === 'SUBMITTED' ? true : false}>
              {translations.filter((trans) => trans.code === 'ICS090').map((trans) => trans.text)}
            </Button>
            <Button
              variant='primary'
              className='assessment-button float-right'
              onClick={() => onSave()}
              disabled={projectStatus === 'SUBMITTED' ? true : false}>
              {translations.filter((trans) => trans.code === 'ICS091').map((trans) => trans.text)}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default AUDProject;
