import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Thanks = () => {
 
  return (
    <>
      <Row className="row-thanks">
        <Col className="first-col-thanks">
          <div className="text-thanks">
            <h1>GRAZIE</h1>
            <span>per aver partecipato al sondaggio!</span>
          </div>
        </Col>
        <Col className="second-col-thanks">
          <img
            src="/img/thanks-survey.jpg"
            alt="thanks-img"
            className="img-thanks"
          />
        </Col>
      </Row>
    </>
  );
};

export default Thanks;
