import React, { Component } from 'react';

class Ico19 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg
        id="ico-19"
        viewBox="0 0 117.62 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        height={this.props.height}
        width={this.props.width}
      >
        <path
          d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z"
          className="esagono"
        />
        <g transform="translate(18.8,34)">
          <path
            d="M50.6667 40L56.043 34.6237C58.0215 35.3548 60.086 35.6989 62.1505 35.6989C66.7097 35.6989 71.2688 33.9355 74.7527 30.4516C80.9462 24.2581 81.6344 14.6237 76.7742 7.65591L65.2473 19.1828L60.5161 14.4516L72.086 3.01075C69.0753 0.989247 65.6344 0 62.1936 0C57.6344 0 53.0753 1.72043 49.5914 5.24731C44.5161 10.3226 43.1398 17.6774 45.4624 24L40 29.3763L31.914 21.2903L28.5591 24.6452L3.95699 0L0 3.95699L24.6452 28.6022L21.2903 31.957L29.3763 40L2.36559 67.0108C-0.55914 69.9355 -0.55914 74.7097 2.36559 77.6344C3.82796 79.0968 5.76344 79.828 7.69892 79.828C9.63441 79.828 11.5699 79.0968 13.0323 77.6344L40 50.6667L67.1398 77.8064C68.6022 79.2688 70.5376 80 72.4731 80C74.4086 80 76.3441 79.2688 77.8064 77.8064C80.7312 74.8817 80.7312 70.1075 77.8064 67.1828L50.6667 40ZM53.5054 9.2043C55.6989 7.01075 58.5376 5.76344 61.5914 5.63441L56.6452 10.5806L52.6882 14.5376L56.6452 18.4946L61.3763 23.2258L65.3333 27.1828L69.2903 23.2258L74.4086 17.9785C74.3656 21.0753 73.1613 24.172 70.7957 26.4946C68.4731 28.8172 65.4193 30.0645 62.1075 30.0645C59.3978 30.0645 56.8172 29.2043 54.7097 27.5699L52.3441 25.2043C50.7097 23.0968 49.8495 20.5161 49.8495 17.8065C49.8925 14.5806 51.1828 11.4839 53.5054 9.2043ZM29.2043 31.914L31.957 29.1613L36.086 33.2903L33.3333 36.043L29.2043 31.914ZM36.043 46.7097L9.03226 73.7204C8.55914 74.1936 7.95699 74.2796 7.65591 74.2796C7.35484 74.2796 6.7957 74.1936 6.27957 73.7204C5.80645 73.2473 5.72043 72.6452 5.72043 72.3441C5.72043 72.043 5.80645 71.4839 6.27957 70.9677L33.2903 43.957L37.2473 40L40 37.2473L43.957 33.2903L48.2581 28.9892C48.6452 29.5054 49.0753 29.9785 49.5484 30.4516C50.0215 30.9247 50.4946 31.3548 51.0108 31.7419L46.7097 36.043L42.7527 40L40 42.7527L36.043 46.7097ZM73.8495 73.8495C73.3763 74.3226 72.7742 74.4086 72.4731 74.4086C72.172 74.4086 71.6129 74.3226 71.0968 73.8495L43.957 46.7097L46.7097 43.957L73.8495 71.0968C74.3226 71.5699 74.4086 72.172 74.4086 72.4731C74.4086 72.7742 74.3226 73.3333 73.8495 73.8495Z"
            className="ico"
          />
        </g>
      </svg>
    );
  }
}
export default Ico19;
