import React, { useState, Suspense, /* useEffect */ } from 'react';
import './styles/main.scss';

import {
  BrowserRouter,
  Route,
  // Router,
  Switch,
  Redirect,
} from 'react-router-dom';

import {
  HomePage,
  //Login,
  Administration,
  Progetti,
  Progetto,
  Valutazione,
  Survey,
  NotFoundPage,
  Thanks,
  Faq,
  AdminLoginPage,
} from '@pages';

/* import {
  UsersManagement,
  OrganizationsManagement,
  TextsManagement,
} from '@pages/administrationpages'; */

import { LayoutBase, LayoutSimple, /* LayoutSideMenu */ } from './layouts';

import { checkAuth, logout, getTranslations } from '@lib/api';

import client from '@lib/graphql';
import { ApolloProvider } from '@apollo/client';

import { Spinner } from 'react-bootstrap';
import { GlobalToastList } from '@components/GlobalToastList';
import { DangerToast } from '@components/GlobalToastList';

function App() {

  getTranslations().then( translations => {

    localStorage.setItem('translations',JSON.stringify(translations))

    let userLanguage = navigator.language.split("-")[1]

    if (!localStorage.getItem('userLanguage')) {
      localStorage.setItem('userLanguage',userLanguage)
    }

  })

  return (
    <Suspense fallback="loading">
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Switch>
            <UnauthenticatedRoute
              path="/"
              exact
              component={() => <HomePage />}
              layout={LayoutSimple}
            />
            <UnauthenticatedRoute
              path="/admin"
              component={() => <AdminLoginPage />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute 
              path="/progetti"
              exact
              component={() => <Progetti />}
              layout={LayoutBase}
              roles={[2, 3]}
            />
            <AuthenticatedRoute
              path="/progetti/:projectId"
              component={(props) => <Progetto {...props} />}
              layout={LayoutBase}
              roles={[2, 3]}
            />
            <UnauthenticatedRoute
              path="/survey/:surveyId"
              component={(props) => <Survey {...props} />}
              layout={LayoutBase}
            />

            {/**
                props contiene match,location,history mi serve per capire la rotta esatta e caricare il giusto sottocomponente
             */}

            <AuthenticatedRoute
              path="/valutazione/:projectId?"
              component={(props) => <Valutazione {...props} />}
              layout={LayoutBase}
              roles={[1, 2, 3]}
            />
            <AuthenticatedRoute
              path="/valutazione/:projectId/esg"
              component={(props) => <Valutazione {...props} />}
              layout={LayoutBase}
              roles={[1, 2, 3]}
            />
            <AuthenticatedRoute
              path="/valutazione/:projectId/sroi"
              component={(props) => <Valutazione {...props} />}
              layout={LayoutBase}
              roles={[1, 2, 3]}
            />
            <AuthenticatedRoute
              path="/gestione-utenti"
              component={(props) => <Administration {...props} />}
              layout={LayoutBase}
              roles={[2, 3]}
            />

           {/*  <AuthenticatedRoute
              path="/administration/users"
              component={() => <UsersManagement />}
              layout={LayoutSideMenu}
            /> */}
            {/* <AuthenticatedRoute
              path="/administration/organizations"
              component={() => <OrganizationsManagement />}
              layout={LayoutSideMenu}
            /> */}
            {/* <AuthenticatedRoute
              path="/administration/textx"
              component={() => <TextsManagement />}
              layout={LayoutSideMenu}
            /> */}
            <UnauthenticatedRoute
              path="/thanks" 
              component={Thanks} 
              layout={LayoutBase}
            />
            <UnauthenticatedRoute
              path="/faq" 
              component={Faq} 
              layout={LayoutBase}
            />

            <LogoutRoute path="/logout" exact />

            <UnauthenticatedRoute 
              component={NotFoundPage} 
              layout={LayoutBase}
            />
          </Switch>
        </BrowserRouter>
      </ApolloProvider>
    </Suspense>
  );
}

const AuthenticatedRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  const [auth, setAuth] = useState(null)
  const [hasRole, setHasRole] = useState(true)

  //console.log('role', localStorage.getItem('ics_role'))
  //console.log(auth)
  //console.log(rest)
  //checkAuth().then(setAuth) //.then(setAuth)  
  React.useEffect(() => {
    let isMounted = true;

    checkAuth().then(retrievedAuth => {
      //console.log('auth in useEffect:', retrievedAuth); 
      //console.log('isMounted:', isMounted);

      if(isMounted ){
        setAuth(retrievedAuth);
        let ics_role = parseInt(localStorage.getItem('ics_role'))
        if(!rest.roles.includes(ics_role)) {
          setHasRole(false)
        }
      } 
    });

    return () => {
      isMounted = false;
    };
  }, [rest.roles])

  //console.log(rest.roles.includes(parseInt(localStorage.getItem('ics_role'))))
  let ics_role = parseInt(localStorage.getItem('ics_role'))
  if(auth && rest.roles.includes(ics_role)) {
    return (   
        <Route {...rest} render={(props) => {
        
        //if(!auth) return null
        //console.log('auth::',auth, auth === 200)
        if(auth !== 200) { 
          DangerToast(
            'Attenzione',
            'Errore di autenticazione.'
          );
          console.log('Errore di autenticazione'); 
        }
        if(auth !== 200) return <Redirect to={{ pathname: '/', state: { referrer: rest.location.pathname } }} />
        return <Layout {...props}>
            <Component {...props} />
            <GlobalToastList />
          </Layout>
        }
        } />
    );
  } else if(!hasRole) {
    return <Redirect to={{ pathname: '/', state: { referrer: rest.location.pathname } }} />
  } else {    
    return <div className="App">
      <div  className="d-flex aligns-items-center justify-content-center align-items-center" style={{height: '100vh'}}>
        <Spinner animation="border" role="status">
          <span style={{visibility: 'hidden'}}>Loading...</span>
        </Spinner>
      </div>
    </div>
  }
}

const UnauthenticatedRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
          <GlobalToastList />
        </Layout>
      )}
    />
  );
};

const LogoutRoute = ({ component: Component, layout: Layout, ...rest }) => {
  logout();
  return <Redirect to="/admin" />;
};

export default App;
