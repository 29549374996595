import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

const Functionality = (props) => {
  const options = {
    buttons: {
      showNextButton: false,
      showPrevButton: false,
      showAutoplayButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
    caption: {
      showCaption: false,
    },
  };

  return (
    <div id="ics-text">
      <Container className="mb-5">
        <Row>
          <Col className="align-self-center">
            <h2 className="title-box">La valutazione</h2>
            <p>
              La piattaforma consente di analizzare in maniera rapida ed
              intuitiva gli impatti economici, sociali e ambientali di politiche
              e investimenti.
              <br />
              L’uso della piattaforma riduce la complessità delle analisi e
              consente stime coerenti, aggiornate e robuste di una vasta gamma
              di esternalità cui si affiancano efficaci tool di rappresentazione
              basati su sofisticate librerie grafiche interattive.
            </p>
          </Col>
          <Col>
            <SimpleReactLightbox>
              <SRLWrapper options={options}>
                <a href="/img/ESG.png" className="light-box-link">
                  <img
                    src="/img/ESG.png"
                    alt="piattaforma"
                    className="img-fluid"
                  />
                  <img
                    src="/img/ico-zoom.svg"
                    alt=""
                    className="ico-absolute"
                    width="57px"
                  />
                </a>
              </SRLWrapper>
            </SimpleReactLightbox>
          </Col>
        </Row>
      </Container>
      <Container style={{ marginBottom: '6rem' }}>
        <Row>
          <Col>
            <SimpleReactLightbox>
              <SRLWrapper options={options}>
                <a href="/img/dashboard-laptop.png" className="light-box-link">
                  <img
                    src="/img/dashboard-laptop.png"
                    alt="piattaforma"
                    className="img-fluid"
                  />
                  <img
                    src="/img/ico-zoom.svg"
                    alt=""
                    className="ico-absolute"
                    width="57px"
                  />
                </a>
              </SRLWrapper>
            </SimpleReactLightbox>
          </Col>
          <Col className="align-self-center" style={{ textAlign: 'right' }}>
            <h2 className="title-box">il metodo</h2>
            <p>
              L’uso della piattaforma riduce i costi e la complessità delle
              analisi e consente stime coerenti, aggiornate e robuste di una
              vasta gamma di esternalità cui si affiancano efficaci tool di
              rappresentazione basati su sofisticate librerie grafiche
              interattive.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="text-center mb-5">
        <Card.Title>Open and interactive</Card.Title>
      </Container>

      <Container className="area-card simple-card mb-1">
        <Row>
          <Col sm={6} className="card-container">
            <Card className="mr-5 my-0">
              <Card.Body>
                <Card.Title>Specificità</Card.Title>
                <div className="card-text">
                  <div className="text">
                    ancorata alle pratiche generali con forte specializzazione
                    sul segmento dello sport e dei beni culturali.
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} className="card-container">
            <Card className="ml-5 my-0">
              <Card.Body>
                <Card.Title>Esperienza</Card.Title>
                <div className="card-text">
                  <div className="text">
                    differenziata in base all'ente proponente e al progetto.
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center">
          <img
            src="/img/dashboard-woman.png"
            alt="piattaforma"
            className="img-fluid"
            style={{ height: '20rem' }}
          />
        </Row>
      </Container>
      <Container className="area-card simple-card my-0">
        <Row>
          <Col sm={6} className="card-container mt-0">
            <Card className="mr-5 mt-2 mb-0">
              <Card.Body>
                <Card.Title>Analisi</Card.Title>
                <div className="card-text">
                  <div className="text">
                    bilanciata ed intuitiva attraverso lo strumento di
                    valutaizone di rischio e impatto.
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} className="card-container mt-0">
            <Card className="ml-5 mt-2 mb-0">
              <Card.Body>
                <Card.Title>Sistema</Card.Title>
                <div className="card-text">
                  <div className="text">
                    di contabilizzazione basato su metriche standard utilizzate
                    dalle organizzazioni economiche internazionali e nazionali.
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Functionality;
