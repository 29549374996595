import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  InputGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
  Accordion,
  Card,
} from 'react-bootstrap';
import { TooltipInfoIcon, ChevronRight } from '@icons';
import {
  getFinDataTaxonomies,
  getSroiTechnicalParams,
  getSroiPeopleParams,
  putSROIFinancialAssessment,
  putSROITechnicalAssessment,
  putSROIPeopleAssessment,
  getPercentageSROI,
  getSroiSimpleDataDefinition,
  putSroiSimpleData
} from '@lib/api';
import { SuccessToast, DangerToast, InfoToast } from '@components/GlobalToastList';
import { InputNumber } from 'primereact';

const ValutazioneSROI = ({ currentProject, translations }) => {
  const [project, setProject] = useState({});
  const [sroiFinancials, setSroiFinancials] = useState([]);
  const [sroiTechnicalParams, setSroiTechnicalParams] = useState([]);
  const [sroiPeopleParams, setSroiPeopleParams] = useState([]);
  const [taxonomies, setTaxonomies] = useState([]);
  const [taxTechnicalParams, setTaxTechnicalParams] = useState([]);
  const [taxPeopleParams, setTaxPeopleParams] = useState([]);
  const [minOfSurveys, setMinOfSurveys] = useState([]);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [maxInvestment, setMaxInvestment] = useState(null);
  const [totalCapex, setTotalCapex] = useState(0);
  const [/* totalOpex */, setTotalOpex] = useState(0); //per eventuale passaggio
  const [revenues, setRevenues] = useState({ AUP: 0, EU: 0, TOTALREVENUES: 0 });

  const [occupationDistributionTotal, setOccupationDistributionTotal] = useState(0);
  const [sroiSimpleData, setSroiSimpleData] = useState([])
  const [sroiSimpleDataDefintion, setSroiSimpleDataDefinition] = useState([])
  const [Q1, setQ1] = useState()
  const [Q2, setQ2] = useState()
  const [Q3, setQ3] = useState()
  const [Q4, setQ4] = useState()
  //console.log('Q4:', Q4);

  //console.log('project', project);
  //console.log('sroiFinancials', sroiFinancials);
  //console.log('taxTechnicalParams', taxTechnicalParams);
  //console.log('taxonomies', taxonomies);

  const handleCloseModal = () => setShowModalAlert(false);

  useEffect(() => {
    setProject(() => ({
      ...currentProject,
    }));

    setMaxInvestment(currentProject.sizes.max);

    getFinDataTaxonomies().then((retrievedTaxonomies) => {
      setTaxonomies(retrievedTaxonomies);
    });

    getSroiTechnicalParams().then((retrievedTechnicalParams) => {
      setTaxTechnicalParams(retrievedTechnicalParams);
    });

    getSroiPeopleParams().then((retrievedPeopleParams) => {
      setTaxPeopleParams(retrievedPeopleParams);
    });

    getPercentageSROI(currentProject.id).then((retrievedPercentageSROI) => {
      setMinOfSurveys(retrievedPercentageSROI.usersThreshold);
    });

    defaultValue();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  useEffect(() => {
    if (project.sroifinancials && project.sroifinancials.length > 0) {
      if (project.interventionClassCode === 'NEW') {
        project.sroifinancials.forEach(sroiFin => {
          const result = isNew(sroiFin.finCode, sroiFin.year);

          if (result) { sroiFin.value = 0 };
        });
      };

      setSroiFinancials(project.sroifinancials);

      const initialValue = 0;
      const finValuesByCode = sroiFinancials.filter(fin => fin.finCode === 'TOTALCAPEX').map(filteredFin => filteredFin.value);
      const sumWithInitial = finValuesByCode.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        initialValue
      );

      setTotalCapex(Number(sumWithInitial).toFixed(2));

    } else if ((!project.sroifinancials || project.sroifinancials.length === 0) && taxonomies.length > 0) {
      //const constructionStartY = project.investmentStartsAt;
      //const operationEndY = constructionStartY + project.expectedLifeSpan;
      const constructionStartY = project.investmentStartsAt;
      const operationEndY = project.operationStartsAt + project.expectedLifeSpan;

      for (let year = constructionStartY, i = 0; year < operationEndY; year++, i++) {
        taxonomies.forEach(tax => {
          setSroiFinancials(prevState => [...prevState, ...[{ finCode: tax.code, year: year, value: 0 }]])
        })
      };
    }


    setSroiSimpleData(project.sroisimple)
        
    let distribution = project.sroisimple && project.sroisimple.length>0 ? 
      project.sroisimple.filter((ssd) => ssd.question.indexOf("1")>0).reduce((tot, elem) => { return tot + parseInt(elem.value) }, 0) : 0
      
    //console.log("distribution", distribution)
    setOccupationDistributionTotal(distribution)
    

    getSroiSimpleDataDefinition().then(ssd => {
      setSroiSimpleDataDefinition(ssd.response)

      let cq = {};
      let sq = {};
      let scq = []
      let ssq = []
   
      ssd.response.forEach(r => {
        let tmp = r.question.toLowerCase().startsWith("C".toLowerCase()) ? cq : sq;
        let stmp = r.question.toLowerCase().startsWith("C".toLowerCase()) ? scq : ssq;

        if (tmp[r.question] === undefined) {
          tmp[r.question] = [];
        }

        tmp[r.question].push({ ans: r.answer, defaultValue: Number(r.defaultValue), possibleValues: JSON.parse(r.values) })
        stmp.push({ question : r.question, answer : r.answer, value : Number(r.defaultValue)})
      });

      let qq = project.sectorCode === "SPORT" ? sq : cq

      setQ1(Object.entries(qq)[0] ?? null)
      setQ2(Object.entries(qq)[1] ?? null)
      setQ3(Object.entries(qq)[2] ?? null)
      setQ4(Object.entries(qq)[3] ?? null)

      if(project.sroisimple && project.sroisimple.length===0) {
         setSroiSimpleData(project.sectorCode === "SPORT" ? ssq : scq )
      }
    })

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    project.sroifinancials,
    project.interventionClassCode,
    project.expectedLifeSpan,
    project.investmentStartsAt,
    project.operationStartsAt,
    project.sectorCode,
    project.sroisimple,
    taxonomies,
  ]);

  useEffect(() => {
    if (project.sroitechnicalparamsassessments && project.sroitechnicalparamsassessments.length > 0) {
      setSroiTechnicalParams(project.sroitechnicalparamsassessments);

    } else if ((!project.sroitechnicalparamsassessments || project.sroitechnicalparamsassessments.length === 0) && taxTechnicalParams.length > 0) {
      taxTechnicalParams.forEach(tech => {
        sroiTechnicalParams.push({ stpCode: tech.code, value: 0 })
      })

      setSroiTechnicalParams(sroiTechnicalParams);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.sroitechnicalparamsassessments, taxTechnicalParams]);

  useEffect(() => {
    if (project.sroipeopleparamsassessments && project.sroipeopleparamsassessments.length > 0) {
      setSroiPeopleParams(project.sroipeopleparamsassessments);

    } else if ((!project.sroipeopleparamsassessments || project.sroipeopleparamsassessments.length === 0) && taxPeopleParams.length > 0) {
      taxPeopleParams.forEach(tech => {
        sroiPeopleParams.push({ sppCode: tech.code, value: 0 })
      })

      setSroiPeopleParams(sroiPeopleParams);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.sroipeopleparamsassessments, taxPeopleParams]);

  useEffect(() => {
    functionTotalPerRow();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sroiFinancials]);

  useEffect(() => {
    if (isConfirmed) { submit() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed]);

  const functionTotalPerRow = code => {
    const initialValue = 0;

    const finValuesByCode = sroiFinancials.filter(fin => fin.finCode === code).map(filteredFin => filteredFin.value);

    const sumWithInitial = finValuesByCode.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );

    //console.log('sumWithInitial', sumWithInitial);
    return code === 'EU' || code === 'AUP' || code === 'TOTALREVENUES' ? sumWithInitial : sumWithInitial.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const checkMaxInvestment = (section) => {
    if (Number(totalCapex) > 0 && Number(totalCapex) > Number(maxInvestment)) {
      setShowModalAlert(true);

      if (section === 'submit') { setIsSubmit(true) } else setIsSubmit(false);

      return true;
    };

    return false
  };

  const checkSetSroiSimpleData = (question, value, defaultValue) => {
    let Q = question.split("-")[0]
    let A = question.split("-")[1] ? question.split("-")[1] : null

    let myvalue = value ? value : defaultValue;

    let data = sroiSimpleData.find(({ question, answer }) => question === Q && answer === A);

    if (data) {
      //console.log("onchange ", sroiSimpleData)
      data.value = parseFloat(myvalue)
    } else {
      sroiSimpleData.push({ question: Q, answer: A, value: parseFloat(myvalue) })
    }

    //Validate distribution
    if (Q === "S1" || Q === "C1") {
      let distribution = sroiSimpleData.filter((ssd) => ssd.question === Q).reduce((tot, elem) => { return tot + parseInt(elem.value) }, 0)
      //console.log("distribution", distribution)
      setOccupationDistributionTotal(distribution)
    }

    //console.log('sroiSimpleData', sroiSimpleData)
    setSroiSimpleData(sroiSimpleData)
  }


  const isNew = (code, year) => {
    let result = false;

    if (project.interventionClassCode === 'NEW' && code !== 'TOTALCAPEX' &&
      (year >= project.investmentStartsAt && year < project.operationStartsAt)) {
      result = true;
    }

    return result;
  };

  const defaultValue = (bottomSection, paramCode) => {
    //paramCode: NEET
    let result;

    switch (bottomSection) {
      case 'technical':
        if (currentProject.sroitechnicalparamsassessments.length > 0) {
          result = sroiTechnicalParams.filter(param => param.stpCode === paramCode).map(filteredParam => filteredParam.value)
        } else {
          result = taxTechnicalParams.filter(param => param.code === paramCode).map(filteredParam => Number(filteredParam.default))
        }

        break;
      case 'people':
        if (currentProject.sroipeopleparamsassessments.length > 0) {
          result = sroiPeopleParams.filter(param => param.sppCode === paramCode).map(filteredParam => filteredParam.value)
        } else {
          result = taxPeopleParams.filter(param => param.code === paramCode).map(filteredParam => Number(filteredParam.default))
        }
        break;
      case 'simplesroi':
        let Q = paramCode.split("-")[0]
        let A = paramCode.split("-")[1] ? paramCode.split("-")[1] : null

        if (currentProject.sroisimple && currentProject.sroisimple.length  > 0) {
         result = currentProject.sroisimple.filter( row => row.question===Q && row.answer===A).map( row => row.value).pop()
         //console.log("con progetto",paramCode, result, Number(result),currentProject.sroisimple)

        } else {
         result = sroiSimpleDataDefintion.filter( row => row.question===Q && row.answer===A).map( row => row.defaultValue).pop()
         //console.log("senza progetto",paramCode, result)
        }

        result = result ? Number(result) : 0
        break;
      default:
        //console.log("default");
        break;
    }

    return result;
  };

  const onChange = (e, section, code, year, setTotal) => {
    //console.log('e.target', typeof e.target.value, e.target.value);

    //console.log('e.target', e.target);
    //console.log('section onchange', section);
    //console.log('code onchange', code);

    let resultWithInitial = 0;
    let sumWithInitial = 0;

    switch (section) {
      case 'financials':
        sroiFinancials.forEach((fin, index) => {
          if (fin.finCode === code && fin.year === year) {
            sroiFinancials[index].value = Number(e.value);
          }
        });

        /**DAL QUARTO ANNO IN POI PER OPEX E EU si usa il valroe del terzo anno */
        if (code === 'TOTALOPEX' || code === 'EU' || code === 'AUP') {
          let thirdValue = 0;
          sroiFinancials
            .filter(fin => fin.finCode === code)
            .forEach((fin, index) => {
              thirdValue = index === 2 ? fin.value : thirdValue;
              fin.value = index > 2 ? thirdValue : fin.value;

            });
        }

        if (code === 'AUP' || code === 'EU') {
          //totale riga
          const finValuesByCode = sroiFinancials.filter(fin => fin.finCode === code).map(filteredFin => filteredFin.value);



          console.log("finValuesByCode", finValuesByCode)

          sumWithInitial = finValuesByCode.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          );


          //totali tuttle le colonne
          const AUPEU = sroiFinancials
            .filter(fin => (fin.finCode === 'AUP') || (fin.finCode === 'EU'))
            .map(filteredFin => { return ({ "code": filteredFin.finCode, "value": filteredFin.value, "year": filteredFin.year }) })
            .reduce((prev, current) => {


              prev[current.year] = (prev[current.year] !== undefined ? prev[current.year] : 1) * current.value

              return prev;

            }, {})

          sroiFinancials.forEach((fin, index) => {
            if (fin.finCode === 'TOTALREVENUES') {
              sroiFinancials[index].value = Number(AUPEU[sroiFinancials[index].year]);
            }
          });

          //console.log("AUPEU",AUPEU)

          //totale colonna
          /* const valuesOfRevenues = sroiFinancials.filter(fin => (fin.finCode === 'AUP' && fin.year === year) || 
             (fin.finCode === 'EU' && fin.year === year)).map(filteredFin => filteredFin.value);
 
           resultWithInitial = valuesOfRevenues[0] * valuesOfRevenues[1];
 
           sroiFinancials.forEach((fin, index) => {
             if (fin.finCode === 'TOTALREVENUES' && fin.year === year) {
               sroiFinancials[index].value = Number(resultWithInitial);
             } 
           });*/

          //totale riga TOTALREVENUES
          const totalRevenuesValues = sroiFinancials.filter(fin => fin.finCode === 'TOTALREVENUES').map(filteredFin => filteredFin.value);

          let sumOfRevenues = totalRevenuesValues.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          );

          setTotal(() => ({
            ...revenues,
            [code]: sumWithInitial,
            TOTALREVENUES: sumOfRevenues,
          }));

        } else {
          const finValuesByCode = sroiFinancials.filter(fin => fin.finCode === code).map(filteredFin => filteredFin.value);

          resultWithInitial = finValuesByCode.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          );

          resultWithInitial = code === 'EU' ? resultWithInitial : parseFloat(resultWithInitial);

          setTotal(resultWithInitial);
        }


        const td = document.getElementById(`td-total-${code}`);
        //console.log('td width', td.offsetWidth);

        if (td && code !== 'EU' && code !== 'AUP') {
          const th = document.getElementById(`th-total-${code}`);
          //console.log('th width', th.offsetWidth);

          th.style.minWidth = `${td.offsetWidth + 10}px`;
          td.style.minWidth = `${td.offsetWidth}px`;
        }

        break;
      case 'technical':
        sroiTechnicalParams.forEach((fin, index) => {
          if (fin.stpCode === code) {
            sroiTechnicalParams[index].value = Number(e.value);
          }
        });
        break;
      case 'people':
         sroiPeopleParams.forEach((fin, index) => {
            if (fin.sppCode === code) {
              sroiPeopleParams[index].value = Number(e.value);
            }
          });
        break;
      case 'simplesroi':
        //console.log("simplesroi", code, e.target.value, e.target.defaultValue)
        checkSetSroiSimpleData(code, e.target.value, e.target.defaultValue);
        break;
      default:
        //console.log('Sorry');
        break;
    }
    console.log("finale sf", sroiPeopleParams)
    setSroiFinancials(sroiFinancials);
    setSroiTechnicalParams(sroiTechnicalParams);
    setSroiPeopleParams(sroiPeopleParams);
  };

  const checkConfirmation = async (boolean) => {
    setIsConfirmed(boolean);

    boolean ? submit() : setShowModalAlert(false);
  };

  const submit = async (e) => {
    e?.preventDefault();

    const resultCheck = checkMaxInvestment('submit');

    if (resultCheck && !isConfirmed) {
      return;
    }

    InfoToast('Salvataggio...', 'Stiamo apportando le modifiche');

    const resultSroiFinancials = await putSROIFinancialAssessment(project.id, sroiFinancials);
    const resultSroiTechnical = await putSROITechnicalAssessment(project.id, sroiTechnicalParams);
    const resultSroiPeople = await putSROIPeopleAssessment(project.id, sroiPeopleParams);
    const resultSroiSimpleData = await putSroiSimpleData (project.id,sroiSimpleData)
    // console.log('resultSroiFinancials submit', resultSroiFinancials);
    // console.log('resultSroiTechnical submit', resultSroiTechnical);
    // console.log('resultSroiPeople submit', resultSroiPeople);

    if (resultSroiFinancials && resultSroiTechnical && resultSroiPeople && resultSroiSimpleData) {
      SuccessToast('Operazione conclusa', 'Progetto modificato correttamente');

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      DangerToast('Attenzione', 'Errore nella modifica del progetto');
    }
  };

  const renderTooltip = (props) => {
    let message = "";

    if (props.popper.state) {
      message = props.popper.state.options.testObj
    };

    return (
      <Tooltip id="button-tooltip" {...props}>
        {message}
      </Tooltip>
    )
  };

  const CreaTabella = (finCODE, typeCode, typeLabel, setTotal) => {
    //typeCode = es. "REVENUES"

    const constructionStartY = project.investmentStartsAt;
    const operationEndY = project.operationStartsAt + project.expectedLifeSpan;

    const colsHeader = [<th key={typeLabel} className="th-width">{typeLabel}</th>];

    for (var year = constructionStartY; year < operationEndY; year++) {
      colsHeader.push(
        <th key={year} className="text-right cell-width pr-4">
          {year}
        </th>
      );
    };

    colsHeader.push(<th id={`th-total-${finCODE}`} key={typeCode} className="text-right th-total total-width pr-3">Totale</th>);

    const rowsTable = [];

    taxonomies.filter(tax => tax.type === typeCode).forEach(filteredTax => {
      //console.log('filteredTax', filteredTax);
      let code = '';

      rowsTable.push(
        <tr key={filteredTax.code}>
          <th className="value-label align-middle th-width">
            {translations.filter(trans => trans.code === filteredTax.code).length > 0 ? translations.filter(trans => trans.code === filteredTax.code).map(trans => trans.text) : filteredTax.text}

            {translations.filter(trans => trans.code === filteredTax.code).map(trans => trans.description).length > 0 ?
              (<OverlayTrigger
                placement="top"
                delay={{ show: 200, hide: 400 }}
                overlay={renderTooltip}
                popperConfig={{ testObj: filteredTax.description }}
              >
                <TooltipInfoIcon className="tooltip-ico" style={{ width: '1rem' }} />
              </OverlayTrigger>)
              : null}
          </th>
          {sroiFinancials.filter(fin => fin.finCode === filteredTax.code).sort((finA, finB) => finA.year - finB.year)
            .map(filteredFin => {
              code = filteredFin.finCode;
              //console.log('filteredFin', filteredFin);

              return (
                <td className="value-label pr-0 cell-width" key={filteredFin.year}>
                  {/* <InputGroup>
                    <InputGroup.Text className="addon-for-number-sroi" style={{ visibility: filteredTax.code === 'EU' ? 'hidden' : 'unset' }}>
                      €
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      step={filteredTax.code === 'AUP' || filteredTax.code === 'EU' ? 1 : 0.01}
                      min="0"
                      id={`${code}${filteredFin.year}`}
                      name={`${code}${filteredFin.year}`}
                      //onInput={Math.round(value)}
                      className="text-right cell-width"
                      onChange={e => onChange(e, 'financials', code, filteredFin.year, setTotal)}
                      onBlur={() => checkMaxInvestment('blur')}
                      onKeyDown={e => (code === 'EU' ? [".", ","].includes(e.key) : ["."].includes(e.key)) && e.preventDefault()}
                      disabled={project.status === 'SUBMITTED' || isNew(code, filteredFin.year) || (code === 'EU' && project.residualLife > 0 && filteredFin.year < project.investmentStartsAt + project.residualLife) || ((typeCode === "OPEX" || code === "EU" || code === "AUP") && filteredFin.year > project.investmentStartsAt + 2) ? true : false}
                      readOnly={code === 'TOTALREVENUES' ? true : false}
                      {...(code === 'TOTALREVENUES' || code === "EU" || code === "TOTALOPEX" || code === 'AUP' ?
                        { value: code === 'AUP' || code === 'TOTALREVENUES' || code === "EU" ? filteredFin.value.toFixed() : filteredFin.value.toFixed(2) } :
                        { defaultValue: filteredFin.value.toFixed(2) }
                      )}
                    /> */}
                     <InputNumber 
                        id={`${code}${filteredFin.year}`}
                        name={`${code}${filteredFin.year}`}
                        min={0}
                        step={filteredTax.code === 'AUP' || filteredTax.code === 'EU' ? 1 : 0.01}
                        minFractionDigits={filteredTax.code === 'AUP' || filteredTax.code === 'EU' || filteredTax.code === 'TOTALREVENUES' ? 0 : 2}
                        maxFractionDigits={filteredTax.code === 'AUP' || filteredTax.code === 'EU' || filteredTax.code === 'TOTALREVENUES' ? 0 : 2}
                        disabled={project.status === 'SUBMITTED' || isNew(code, filteredFin.year) || (code === 'EU' && project.residualLife > 0 && filteredFin.year < project.investmentStartsAt + project.residualLife) || ((typeCode === "OPEX" || code === "EU" || code === "AUP") && filteredFin.year > project.investmentStartsAt + 2) ? true : false}
                        readOnly={code === 'TOTALREVENUES' ? true : false}
                        onChange={e => onChange(e, 'financials', code, filteredFin.year, setTotal)}
                        onBlur={() => checkMaxInvestment('blur')}
                        mode="decimal"
                        locale={`${localStorage.getItem('userLanguage').toLowerCase()}-${localStorage.getItem('userLanguage')}`}
                        className="md t-right"
                        suffix={filteredTax.code === 'EU' ? '' : ' €'}
                        value={filteredFin.value}
                     /> 
                  {/* </InputGroup> */}
                </td>
              );
            })}
          <td id={`td-total-${code}`} className="value-label pr-0 text-right align-middle total-width td-total">
            {filteredTax.code === 'AUP' || filteredTax.code === 'EU' ? null : (<>
              <strong id={code}>
                {functionTotalPerRow(code)}
              </strong>
              <strong>{filteredTax.code === 'EU' ? '' : ' €'}&nbsp;</strong>
            </>)}

          </td>
        </tr>
      );

    });

    return (
      <Table responsive className="sroi-table" key={typeCode}>
        <thead>
          <tr>
            {colsHeader}
          </tr>
        </thead>
        <tbody>
          {rowsTable}
        </tbody>
      </Table>
    );
  };

  if (currentProject.sectorCode === 'EVENTI') return null;

  return (
    <>
      {true  ?
        <></> :
        <div style={{ marginTop: '3rem', textAlign: 'center' }}>
          <div className="simple-box instruction sroi text-left">
            <div className="row">
              <div className="col-lg-12">
                <Accordion className="fade-in-accordion" id="fade-in-accordion">
                  <Accordion.Collapse style={{ marginTop: '2rem' }} eventKey={1} timeout={1000}>
                    <Card.Body>
                      <Row className="gap-5">
                        <Col className="px-4 mb-2">
                          La <b>valutazione dello SROI</b> è corredata da un sondaggio destinato all’utenza (attuale e futura) del promotore/proponente.<br />
                          Tale sondaggio è necessario all’elaborazione dell’analisi della domanda.<br /><br />

                          Il sondaggio è condivisibile via URL in modalità open (è possibile inoltrare il link generato cliccando sul pulsante "Clicca qui" via mail, o condividerlo attraverso il proprio dispositivo elettronico).<br />
                          È possibile rispondere alle brevi domande del sondaggio tramite qualsiasi dispositivo elettronico dotato di web browser.<br /><br />

                          <u style={{ textDecorationColor: '#c2c923' }}>Solo rispondendo al numero minimo di sondaggi la sezione sarà completa al 100%.</u><br /><br />

                          Il sondaggio è completamente anonimo.
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                  <Accordion.Toggle as="h5" className="accordion-title" eventKey={1} style={{ margin: '0' }}>
                    <div className="row p-2">
                      <div className="col-10 px-3">
                        <div className="d-flex align-items-center text-uppercase" style={{ height: '100%' }}>
                          Istruzioni
                        </div>
                      </div>
                      <div className="col-2 align-self-center pl-2 px-0 text-center">
                        <ChevronRight className="accordion-arrow" />
                      </div>
                    </div>
                  </Accordion.Toggle>
                </Accordion>
              </div>
            </div>
          </div>

          {/* <p>Per completare la sezione al 100% occorre rispondere al sondaggio seguente.</p> */}
          <h5 className='mb-3'>
            Link al sondaggio: <a href={`/survey/${currentProject.sroisurveys.uuid}`} target="_blank" rel="noreferrer noopener">Clicca qui</a>
          </h5>
          <p>Persone che hanno risposto al sondaggio: {currentProject.sroisurveyinstances.length} su un minimo di {minOfSurveys}</p>
        </div>
      }

      <Form onSubmit={e => submit(e)} id="valutazione-sroi-form">
        <div className="simple-box" style={{ marginTop: '40px' }}>
          <Row>
            <Col className="px-4">
              <div className="main-wrapper">
                {Object.keys(project).length > 0 && sroiFinancials.length > 0 ?
                  CreaTabella('TOTALCAPEX', 'CAPEX', 'Investimento in conto capitale (Capex)', setTotalCapex) : null}
              </div>
              {project.proponentCode === 'ET' ?
                <></> :
                <>
                  <div className="main-wrapper">
                    {Object.keys(project).length > 0 && sroiFinancials.length > 0 ?
                      CreaTabella('TOTALOPEX', 'OPEX', 'Costi di gestione (Opex)', setTotalOpex) : null}
                  </div>
                  <div className="main-wrapper">
                    {Object.keys(project).length > 0 && sroiFinancials.length > 0 ?
                      CreaTabella('TOTALREVENUES', 'REVENUES', `Ricavi attesi${project.interventionClassCode !== 'NEW' ? ' incrementali' : ''}`, setRevenues)
                      : null}
                  </div>
                </>
              }

              {/* SIMPLE SORI BEGINS*/}
              <div id="simple-sroi-div">
                <Row className="m-0">
                  <span className="simple-sroi-span">Rispondere alle seguenti domande</span>
                </Row>

                {Q1 && Q1[0] &&
                  <Row /*DOMANDA 1*/ className="mb-5 mt-3 px-3 align-items-center">
                    <Col className="col-5">
                      <Form.Group className="m-0">
                        <Form.Label className="m-0">
                          {translations.filter(trans => trans.code === Q1[0]).length > 0 ? translations.filter(trans => trans.code === Q1[0]).map(trans => trans.text) : Q1[0]}
                          {translations.filter(trans => trans.code === Q1[0]).map(trans => trans.description.length > 0 ?
                            (<OverlayTrigger
                              key={"over-" + Q1[0]}
                              placement="top"
                              delay={{ show: 200, hide: 400 }}
                              overlay={renderTooltip}
                              popperConfig={{ testObj: trans.description }}
                             >
                              <TooltipInfoIcon className="tooltip-ico" style={{ width: '1rem' }} />
                            </OverlayTrigger>)
                            : null)
                          }
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col className="col-05 question-line list-perc-question"/>
                    <Col className="pr-0 pl-0">
                      {Q1[1].map((occupation, index) => 
                        index < 5 ? 
                          <Form.Group as={Row} key={Q1[0] + occupation.ans} className="mb-3 align-items-center" controlId={Q1[0] + occupation.ans}>
                            <Col style={{minWidth: '6rem'}}>
                              <Form.Label className="mb-0">
                                {translations.filter(trans => trans.code === occupation.ans).length > 0 ? translations.filter(trans => trans.code === occupation.ans).map(trans => trans.text) : occupation.ans}
                              </Form.Label>
                            </Col>
                            <Col>
                              <InputGroup>
                                 <Form.Control
                                    className="percentage-max-width"
                                    type="number"
                                    name={Q1[0] + occupation.ans}
                                    step={1}
                                    min={0}
                                    max={100}
                                    size="lg"
                                    onChange={e => onChange(e, 'simplesroi', (Q1[0] + "-" + occupation.ans))}
                                    disabled={project.status === 'SUBMITTED' ? true : false}
                                    defaultValue={defaultValue('simplesroi', (Q1[0] + "-" + occupation.ans))}
                                 />
                                 <InputGroup.Text className="addon-for-number">%</InputGroup.Text>
                              </InputGroup>     
                            </Col>
                          </Form.Group> : null
                      )}
                    </Col>
                    <Col className="pr-0 pl-5">
                      {Q1[1].map((occupation, index) => 
                        index >= 5 ?
                          <Form.Group as={Row} key={Q1[0] + occupation.ans} className="mb-3 align-items-center" controlId={Q1[0] + occupation.ans}>
                            <Col style={{minWidth: '6rem'}}>
                              <Form.Label className="mb-0">
                                {translations.filter(trans => trans.code === occupation.ans).length > 0 ? translations.filter(trans => trans.code === occupation.ans).map(trans => trans.text) : occupation.ans}
                              </Form.Label>
                            </Col>
                            <Col>
                              <InputGroup className="justify-content-end">
                                 <Form.Control
                                    className="percentage-max-width"
                                    type="number"
                                    name={Q1[0] + occupation.ans}
                                    step={1}
                                    min={0}
                                    max={100}
                                    size="lg"
                                    onChange={e => onChange(e, 'simplesroi', (Q1[0] + "-" + occupation.ans))}
                                    disabled={project.status === 'SUBMITTED' ? true : false}
                                    defaultValue={defaultValue('simplesroi', (Q1[0] + "-" + occupation.ans))}
                                 />
                                 <InputGroup.Text className="addon-for-number">%</InputGroup.Text>
                              </InputGroup>
                            </Col>
                          </Form.Group> : null
                      )}
                      <Row 
                        style={{paddingLeft: '1rem', paddingRight: '1rem', marginBottom: 0}} 
                        className={occupationDistributionTotal > 100 ? "alert alert-danger" : occupationDistributionTotal === 100 ? "alert alert-success" : "alert alert-warning"}
                      >
                        <Col className="p-0"><span><strong>Totale</strong></span></Col>
                        <Col className="p-0 text-right" style={{flexBasis: '30%'}}><strong>{occupationDistributionTotal}%</strong></Col>
                      </Row>
                    </Col>
                  </Row>
                }

                {Q2 && Q2[0] &&
                <>
                  <hr className="m-hr"/>
                  <Row className="mx-0 align-items-center" style={{marginTop: '4rem'}}>
                    <Col className="col-5">
                      <Form.Group className="m-0">
                        <Form.Label className="m-0">
                          {translations.filter(trans => trans.code === Q2[0]).length > 0 ? translations.filter(trans => trans.code === Q2[0]).map(trans => trans.text) : Q2[0]}
                          {translations.filter(trans => trans.code === Q2[0]).map(trans => trans.description.length > 0 ?
                            (<OverlayTrigger
                              key={"over-" + Q2[1]}
                              placement="top"
                              delay={{ show: 200, hide: 400 }}
                              overlay={renderTooltip}
                              popperConfig={{ testObj: trans.description }}                                 >
                              <TooltipInfoIcon className="tooltip-ico" style={{ width: '1rem' }} />
                            </OverlayTrigger>)
                            : null)
                          }
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col className="col-05 question-line other-question"/>
                    <Col className="px-0">
                      <Form.Group key={Q2[0]} controlId={Q2[0]} className="m-0">
                        <InputGroup>
                           <Form.Control      
                              className="percentage-max-width"                     
                              type="number"
                              name={Q2[0]}
                              step={1}
                              min={0}
                              max={100}
                              size="lg"
                              onChange={e => onChange(e, 'simplesroi', Q2[0] + "-" + Q2[1][0].ans)}
                              disabled={project.status === 'SUBMITTED' ? true : false}
                              defaultValue={defaultValue('simplesroi', (Q2[0] + "-" + Q2[1][0].ans))}
                           />
                           <InputGroup.Text className="addon-for-number">%</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
                }

                {Q3 && Q3[0] &&
                <>
                  <hr className="m-hr"/>
                  <Row className="mx-0 align-items-center" style={{marginTop: '4rem'}}>
                    <Col className="col-5">
                      <Form.Group className="m-0">
                        <Form.Label className="m-0">
                          {translations.filter(trans => trans.code === Q3[0]).length > 0 ? translations.filter(trans => trans.code === Q3[0]).map(trans => trans.text) : Q3[0]}
                          {translations.filter(trans => trans.code === Q3[0]).map(trans => trans.description.length > 0 ?
                            (<OverlayTrigger
                              key={"over-" + Q3[0]}
                              placement="top"
                              delay={{ show: 200, hide: 400 }}
                              overlay={renderTooltip}
                              popperConfig={{ testObj: trans.description }}>
                              <TooltipInfoIcon className="tooltip-ico" style={{ width: '1rem' }} />
                            </OverlayTrigger>)
                            : null)
                          }
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col className="col-05 question-line other-question"/>
                    <Col className="px-0">
                      <Form.Group key={Q3[0]} controlId={Q3[0]} className="radio-sroi-simple m-0">
                        <Row className="align-items-center">
                        {Q3[1][0].possibleValues.map(ans => { 
                           //console.log("è ceccato? ", (Q3[0] + "-" + Q3[1][0].ans), ans[Object.keys(ans)[0]], parseFloat(ans[Object.keys(ans)[0]])===parseFloat(defaultValue('simplesroi', (Q3[0] + "-" + Q3[1][0].ans))), )
                           return (
                           <Col key={Object.keys(ans)[0]}>
                              <Form.Check 
                                 type="radio"
                                 inline
                                 defaultValue={parseFloat(ans[Object.keys(ans)[0]])}
                                 name={Q3[0]}
                                 id={`${Q3[0]+Object.keys(ans)[0]}`}
                                 label={translations.filter(trans => trans.code === Q3[0] + Object.keys(ans)[0]).length > 0 ? translations.filter(trans => trans.code === Q3[0] + Object.keys(ans)[0]).map(trans => trans.text) : Q3[0] + Object.keys(ans)[0]}
                                 onChange={e => onChange(e, 'simplesroi', Q3[0]+ "-" + Q3[1][0].ans)}
                                 defaultChecked={parseFloat(ans[Object.keys(ans)[0]])===parseFloat(defaultValue('simplesroi', (Q3[0] + "-" + Q3[1][0].ans)))}
                              />
                           </Col>
                          )}
                        )}
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
                }

                {Q4 && Q4[0] &&
                <>
                  <hr className="m-hr"/>
                  <Row className="mx-0 align-items-center" style={{marginTop: '4rem'}}>
                    <Col className="col-5">
                      <Form.Group className="m-0">
                        <Form.Label className="m-0">
                          {translations.filter(trans => trans.code === Q4[0]).length > 0 ? translations.filter(trans => trans.code === Q4[0]).map(trans => trans.text) : Q4[0]}
                          {translations.filter(trans => trans.code === Q4[0]).map(trans => trans.description.length > 0 ?
                            (<OverlayTrigger
                              key={"over-" + Q4[0]}
                              placement="top"
                              delay={{ show: 200, hide: 400 }}
                              overlay={renderTooltip}
                              popperConfig={{ testObj: trans.description }}                                 >
                              <TooltipInfoIcon className="tooltip-ico" style={{ width: '1rem' }} />
                            </OverlayTrigger>)
                            : null)
                          }
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col className="col-05 question-line other-question"/>
                    <Col className="px-0">
                      <Form.Group key={Q4[0]} controlId={Q4[0]} className="radio-sroi-simple m-0">
                      <Row className="align-items-center">
                        {Q4[1][0].possibleValues.map(ans =>
                        <Col key={Object.keys(ans)[0]}>
                          <Form.Check
                            type="radio"
                            inline
                            value={ans[Object.keys(ans)[0]]}
                            name={Q4[0]}
                            id={`${Q4[0]+Object.keys(ans)[0]}`}
                            label={translations.filter(trans => trans.code === Q4[0] + Object.keys(ans)[0]).length > 0 ? translations.filter(trans => trans.code === Q4[0] + Object.keys(ans)[0]).map(trans => trans.text) : Q4[0] + Object.keys(ans)[0]}
                            onChange={e => onChange(e, 'simplesroi', Q4[0]+ "-" + Q4[1][0].ans)}
                            defaultChecked={parseFloat(ans[Object.keys(ans)[0]])===parseFloat(defaultValue('simplesroi', (Q4[0] + "-" + Q4[1][0].ans)))}
                          />
                        </Col>
                        )}
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
                }
              </div>
              {/* SIMPLE SROI ENDS */}

              {project.proponentCode === 'ET' ?
                <></> :
                <div id="technical-people-div">
                  <Row className="m-0">
                    <span className='description'>
                      Se applicabile, indicare di seguito la potenza dell’impianto FER che verrà installato nell’ambito del progetto ed i benefici stimati di eventuali interventi di efficientamento in termini di riduzione dei consumi energetici, di gas per il riscaldamento e di acqua.
                    </span>
                  </Row>

                  <Row className="mb-4 mt-3 px-3">
                    {taxTechnicalParams.sort((techParamA, techParamB) => techParamA.name.localeCompare(techParamB.name))
                      .map(techParam => (
                        <Col key={techParam.code}>
                          <Form.Group controlId={techParam.code}>
                            <Form.Label>
                              {translations.filter(trans => trans.code === techParam.code).length > 0 ? translations.filter(trans => trans.code === techParam.code).map(trans => trans.text) : techParam.name}

                              {techParam.description ? (<OverlayTrigger
                                placement="top"
                                delay={{ show: 200, hide: 400 }}
                                overlay={renderTooltip}
                                popperConfig={{ testObj: techParam.description }}
                              >
                                <TooltipInfoIcon className="tooltip-ico" style={{ width: '1rem' }} />
                              </OverlayTrigger>) : null}
                            </Form.Label>
                            <div className="input-width text-center middle-select">
                                 <InputNumber 
                                    name={techParam.code}
                                    onChange={(e) => onChange(e, 'technical', techParam.code)}
                                    mode="decimal"
                                    minFractionDigits={0} 
                                    maxFractionDigits={2} 
                                    locale={`${localStorage.getItem('userLanguage').toLowerCase()}-${localStorage.getItem('userLanguage')}`}
                                    min={0}
                                    step={0.1}
                                    disabled={project.status === 'SUBMITTED' || (techParam.code !== 'FERKW' && project.interventionClassCode === 'NEW') ? true : false}
                                    className="lg t-left"
                                    value={techParam.code !== 'FERKW' && project.interventionClassCode === 'NEW' ? 0 : defaultValue('technical', techParam.code)}
                                 />
                             </div>
                          </Form.Group>
                        </Col>
                      ))
                    }
                  </Row>

                  <Row className="px-3">
                    {taxPeopleParams.sort((peopleParamA, peopleParamB) => peopleParamA.name.localeCompare(peopleParamB.name))
                      .map(peopleParam => (
                        <Col key={peopleParam.code}>
                          <Form.Group controlId={peopleParam.code}>
                            <Form.Label>
                              {translations.filter(trans => trans.code === peopleParam.code).length > 0 ? translations.filter(trans => trans.code === peopleParam.code).map(trans => trans.text) : peopleParam.name}
                              {peopleParam.description ? (<OverlayTrigger
                                placement="top"
                                delay={{ show: 200, hide: 400 }}
                                overlay={renderTooltip}
                                popperConfig={{ testObj: peopleParam.description }}
                              >
                                <TooltipInfoIcon className="tooltip-ico" style={{ width: '1rem' }} />
                              </OverlayTrigger>) : null}
                            </Form.Label>
                            <div className="input-width text-center middle-select">
                              <InputNumber 
                                 name={peopleParam.code}
                                 onChange={(e) => onChange(e, 'people', peopleParam.code)}
                                 mode="decimal"
                                 locale={`${localStorage.getItem('userLanguage').toLowerCase()}-${localStorage.getItem('userLanguage')}`}
                                 min={0}
                                 step={1}
                                 disabled={project.status === 'SUBMITTED' ? true : false}
                                 className="lg t-left"
                                 value={defaultValue('people', peopleParam.code)}
                              />
                             </div>
                          </Form.Group>
                        </Col>
                      ))
                    }
                    <Col></Col>
                    <Col></Col>
                  </Row>
                </div>
              }
            </Col>
          </Row>
        </div>

        {project.status === 'DRAFT' && (
          <Button
            variant="primary"
            className="assessment-button"
            type="submit"
            form="valutazione-sroi-form"
          >
            SALVA
          </Button>
        )}
      </Form>

      <Modal show={showModalAlert} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Attenzione</Modal.Title>
        </Modal.Header>
        <Modal.Body>Il totale degli investimenti Capex supera la taglia dell'investimento selezionato {maxInvestment ? `(max ${Number(maxInvestment).toLocaleString()}) €` : ''}</Modal.Body>
        <Modal.Footer>
          {!isSubmit ?
            (<Button variant="primary" onClick={handleCloseModal}>
              Chiudi
            </Button>) :
            (<>
              <Button variant="danger" onClick={() => checkConfirmation(false)}>
                Annulla
              </Button>
              <Button variant="primary" onClick={() => checkConfirmation(true)}>
                Conferma
              </Button>
            </>)
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ValutazioneSROI;
