import React, { Component } from 'react';

class Ico44 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg
        id="ico-44"
        viewBox="0 0 117.62 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        height={this.props.height}
        width={this.props.width}
      >
        <path
          d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z"
          className="esagono"
        />
        <g transform="translate(31.8,39.5)">
          <path
            d="M27.0002 13.6663C26.1161 13.6663 25.2683 14.0175 24.6431 14.6427C24.018 15.2678 23.6668 16.1156 23.6668 16.9997V53.6663C23.6668 54.5504 24.018 55.3982 24.6431 56.0234C25.2683 56.6485 26.1161 56.9997 27.0002 56.9997C27.8842 56.9997 28.7321 56.6485 29.3572 56.0234C29.9823 55.3982 30.3335 54.5504 30.3335 53.6663V16.9997C30.3335 16.1156 29.9823 15.2678 29.3572 14.6427C28.7321 14.0175 27.8842 13.6663 27.0002 13.6663ZM50.3335 0.333008C49.4494 0.333008 48.6016 0.684198 47.9765 1.30932C47.3513 1.93444 47.0002 2.78229 47.0002 3.66634V53.6663C47.0002 54.5504 47.3513 55.3982 47.9765 56.0234C48.6016 56.6485 49.4494 56.9997 50.3335 56.9997C51.2175 56.9997 52.0654 56.6485 52.6905 56.0234C53.3156 55.3982 53.6668 54.5504 53.6668 53.6663V3.66634C53.6668 2.78229 53.3156 1.93444 52.6905 1.30932C52.0654 0.684198 51.2175 0.333008 50.3335 0.333008ZM3.66683 26.9997C2.78277 26.9997 1.93493 27.3509 1.30981 27.976C0.684686 28.6011 0.333496 29.449 0.333496 30.333V53.6663C0.333496 54.5504 0.684686 55.3982 1.30981 56.0234C1.93493 56.6485 2.78277 56.9997 3.66683 56.9997C4.55088 56.9997 5.39873 56.6485 6.02385 56.0234C6.64897 55.3982 7.00016 54.5504 7.00016 53.6663V30.333C7.00016 29.449 6.64897 28.6011 6.02385 27.976C5.39873 27.3509 4.55088 26.9997 3.66683 26.9997Z"
            className="ico"
          />
        </g>
      </svg>
    );
  }
}
export default Ico44;
