import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

const NotFoundPage = () => {

return (
    <div className="simple-box text-center error">
        <Row>
            <Col>
                <img
                    src="/img/404-error.png"
                    alt="error"
                    className="img-fluid error-img"
                />
                <h2>La pagina che stavi cercando non è stata trovata</h2>
            
                <Link to="/progetti">Ritorna ai progetti</Link> 
            </Col>
        </Row>
        
    </div>
    );
};

export default NotFoundPage;