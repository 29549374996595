import React from 'react';
import {
  Ico3,
  Ico8,
  Ico19,
  Ico21,
  Ico29,
  Ico33,
  Ico35,
  Ico36,
  Ico42,
  Ico44,
  Ico45,
  Ico47,
  Ico89,
  Ico90,
  Ico91,
  Ico92,
  Ico93,
} from '@icons/new';
import { Container, Row, Col, CardColumns, Card } from 'react-bootstrap';

function Scenari() {
  return (
    <div id="icoPage" className="megaContent">
      <Container fluid>
        <Row>
          <Col>
            <p>
              Larghezza ed altezza possono essere settate da css o con width,
              height, larghezza ed altezza corrispondono alla dimensione
              dell'esagono.
            </p>
            <p>
              La classe "nobox" leva l'esagono, ma le dimensioni dell'icona
              interna restano uguali a quelle con l'esagono, quindi considerare
              l'icona interna come rimpicciolita di circa 15cm per lato rispetto
              alla misura width ed height data.
            </p>
            <p>La classe base è per le icone blu scuro</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <CardColumns>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 3</Card.Title>
                  <Card.Text>
                    <Ico3 className="noBox" />
                    <Ico3 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 8</Card.Title>
                  <Card.Text>
                    <Ico8 className="noBox" />
                    <Ico8 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 19</Card.Title>
                  <Card.Text>
                    <Ico19 className="noBox" />
                    <Ico19 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 21</Card.Title>
                  <Card.Text>
                    <Ico21 className="noBox" />
                    <Ico21 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 29</Card.Title>
                  <Card.Text>
                    <Ico29 className="noBox" />
                    <Ico29 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 33</Card.Title>
                  <Card.Text>
                    <Ico33 className="noBox" />
                    <Ico33 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 35</Card.Title>
                  <Card.Text>
                    <Ico35 className="noBox" />
                    <Ico35 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 36</Card.Title>
                  <Card.Text>
                    <Ico36 className="noBox" />
                    <Ico36 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 42</Card.Title>
                  <Card.Text>
                    <Ico42 className="noBox" />
                    <Ico42 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 44</Card.Title>
                  <Card.Text>
                    <Ico44 className="noBox" />
                    <Ico44 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 45</Card.Title>
                  <Card.Text>
                    <Ico45 className="noBox" />
                    <Ico45 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 47</Card.Title>
                  <Card.Text>
                    <Ico47 className="noBox" />
                    <Ico47 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 89</Card.Title>
                  <Card.Text>
                    <Ico89 className="noBox" />
                    <Ico89 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 90</Card.Title>
                  <Card.Text>
                    <Ico90 className="noBox" />
                    <Ico90 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 91</Card.Title>
                  <Card.Text>
                    <Ico91 className="noBox" />
                    <Ico91 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 92</Card.Title>
                  <Card.Text>
                    <Ico92 className="noBox" />
                    <Ico92 />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>ICO 93</Card.Title>
                  <Card.Text>
                    <Ico93 className="noBox" />
                    <Ico93 />
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardColumns>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Scenari;
