import React, { useState, useEffect } from 'react';
import { Row, Form, Button } from 'react-bootstrap';
import { getAUDProjectsByProject, postAUDProject, deleteAUDProject } from '@lib/api';
import { IcoPlus } from '@icons';
import AUDProject from './AUDProject';

const ValutazioneSROIAUD = ({ currentProject, translations, isUpdated, setIsUpdated }) => {
  const [project, setProject] = useState({});
  const [AUDProjects, setAUDProjects] = useState([]);

  //   let defaultAUDPRoject = {
  //     name: 'Nuovo Progetto Audiovisivo',
  //     projectType: 'LONG',
  //     productionYearStart: 2023,
  //     productionDurationWeeks: 8,
  //     minsOperaDuration: 85,
  //     onaircinemaYear: 2024,
  //     onairtelevisionYear: 2025,
  //     onairplatformYear: 2025,
  //     productionCosts: 15000000,
  //     otherCosts: 30000,
  //     productionGenre: 'COM',
  //     distributionContractCinema: true,
  //     contractCinemas: 200,
  //     distributionContractTelevision: true,
  //     distributionContractPlatforms: true,
  //     estimatedCinemaUsers: 932400,
  //   };

  let defaultAUDPRoject = {
    name: 'Nuovo Progetto Audiovisivo',
    projectType: 'LONG',
    productionYearStart: 2023,
    productionDurationWeeks: 8,
    minsOperaDuration: 85,
    onaircinemaYear: 2024,
    onairtelevisionYear: 2025,
    onairplatformYear: 2025,
    otherCosts: 30000,
    productionGenre: 'COM',
    distributionContractCinema: true,
    contractCinemas: 200,
    distributionContractTelevision: true,
    distributionContractPlatforms: true,
    estimatedCinemaUsers: 932400,
    aboveTheLine: 12000000,
    belowTheLine: 3000000,
  };

  /**FUNCTIONS */
  const submit = (e) => {
    console.log('submit', e);
  };

  const handleAddAudProject = () => {
    postAUDProject(project.id, defaultAUDPRoject).then((newAudProject) => {
      console.log(defaultAUDPRoject); // Qui mi restituisce quello modificato da me
      console.log('newAudProject', newAudProject); // Qui invece con i vecchi dati

      if (newAudProject.code === 200) {
        let AUDProjectArr = [];

        AUDProjects.forEach((a) => {
          AUDProjectArr.push(a);
        });

        AUDProjectArr.push(newAudProject.response);
        setAUDProjects(AUDProjectArr);
      }
    });
  };

  const handleChangesInAudProject = (AUDproject) => {
    let AUDProjectArr = [];
    AUDProjects.forEach((a) => {
      if (a.id === AUDproject.id) {
        AUDProjectArr.push(AUDproject);
      } else {
        AUDProjectArr.push(a);
      }
    });

    setAUDProjects(AUDProjectArr);
    setIsUpdated(true);
  };

  const handleDeleteInAudProject = (AUDproject) => {
    deleteAUDProject(AUDproject.id).then((success) => {
      if (success) {
        getAUDProjectsByProject(currentProject.id).then((resp) => {
          if (resp.code === 200) {
            setAUDProjects(resp.response);
            setIsUpdated(true);
          }
        });
      }
    });
  };

  useEffect(() => {
    setProject(() => ({
      ...currentProject,
    }));

    getAUDProjectsByProject(currentProject.id).then((resp) => {
      if (resp.code === 200) {
        setAUDProjects(resp.response);
      }
    });
  }, [currentProject]);

  return (
    <>
      <Form onSubmit={(e) => submit(e)} id='valutazione-aud-sroi-form'>
        {AUDProjects &&
          AUDProjects.length > 0 &&
          AUDProjects.map((thisAUDProject) => (
            <AUDProject
              key={thisAUDProject.id}
              currentAUDProject={thisAUDProject}
              translations={translations}
              projectStatus={project.status}
              handleChangesInAudProject={handleChangesInAudProject}
              handleDeleteInAudProject={handleDeleteInAudProject}
            />
          ))}
      </Form>

      {project.status === 'DRAFT' && (
        <Button variant='info' className='add-aud-button' onClick={handleAddAudProject}>
          <Row className='align-items-center py-1 px-3'>
            <IcoPlus className='light-color ico-small plus mr-1' />
            <span className='d-md-inline'>
              {translations.filter((trans) => trans.code === 'ICS092').map((trans) => trans.text)}
            </span>
          </Row>
        </Button>
      )}
    </>
  );
};
export default ValutazioneSROIAUD;
