import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import { Button, Modal, OverlayTrigger, Tooltip, Form, Row } from 'react-bootstrap';
//import { Trans, propTrans } from '@lib/i18n';
import { rounder } from '@lib/helpers';
import { Print } from '@icons';
import { getPercentageESG, getPercentageSROI, putChangeStatusOnSubmitted, logout } from '@lib/api';
import { SuccessToast, DangerToast, InfoToast } from '@components/GlobalToastList';

const stateColorsWheel = {
  onGoing: ['#666666', '#e5e5e5'],
  enough: ['#C2C923', '#e5e5e5'],
  current: ['#1C2743', '#e5e5e5'],
  zero: ['#e5e5e5'],
};

const AnimatedDonutChart = ({ chartId, data, current, progress }) => {
  const plotData = [];

  Object.keys(data).forEach((key) => {
    if (key !== 'total' && key !== 'threshold' && data[key] !== 0) {
      plotData.push(data[key]);
    }
  });

  useEffect(() => {
    let colors = current
      ? stateColorsWheel['current']
      : data.done === 0
      ? stateColorsWheel['zero']
      : data.done >= data.threshold
      ? stateColorsWheel['enough']
      : stateColorsWheel['onGoing']; //colorsWheel[chartId];//colorbrewer(plotData.length);

    /*
      let sizes = {
        innerRadius: 75,
        outerRadius: 100
      };*/

    let sizes = {
      innerRadius: 80,
      outerRadius: 100,
    };

    let durations = {
      entryAnimation: 2000,
    };

    d3.select(`#${chartId}`).html('');

    let generator = d3.pie().padAngle(0.04).sort(null);

    let chart = generator(plotData);

    let arcs = d3
      .select(`#${chartId}`)
      .append('g')
      .attr('transform', 'translate(100, 100)')
      .selectAll('path')
      .data(chart)
      .enter()
      .append('path')
      .style('fill', (d, i) => colors[i])
      .attr('data-value', (d, i) => d.data)
      .attr('data-label', (d, i) => {
        const label = Object.keys(data).find((key) => data[key] === d.data);
        return label;
      });

    let g = d3.select(`#${chartId}`).selectAll('g');

    g.append('text')
      .attr('text-anchor', 'middle')
      .attr('font-size', '2em')
      .attr('y', 12)
      .attr('font-weight', 'bold')
      .text(rounder(data.done) + '%'); //number in circle

    let angleInterpolation = d3.interpolate(generator.startAngle()(), generator.endAngle()());

    let innerRadiusInterpolation = d3.interpolate(0, sizes.innerRadius);
    let outerRadiusInterpolation = d3.interpolate(0, sizes.outerRadius);

    let arc = d3.arc();

    arcs
      .transition()
      .duration(durations.entryAnimation)
      .attrTween('d', (d) => {
        let originalEnd = d.endAngle;
        return (t) => {
          let currentAngle = angleInterpolation(t);
          if (currentAngle < d.startAngle) {
            return '';
          }

          d.endAngle = Math.min(currentAngle, originalEnd);

          return arc(d);
        };
      });

    d3.select(`#${chartId}`)
      .transition()
      .duration(durations.entryAnimation)
      .tween('arcRadii', () => {
        return (t) =>
          arc.innerRadius(innerRadiusInterpolation(t)).outerRadius(outerRadiusInterpolation(t));
      })
      .on('end', () => {
        //console.log(InvestmentChartIcon)
        //d3.select(`#${chartId}`).node().append()
      });

    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'svg-tooltip')
      .attr('class', 'mt-5')
      .style('position', 'absolute')
      .style('visibility', 'hidden');

    const mouseEnterCallback = (d) => {
      d3.select(d.target).attr('stroke-width', '2').attr('stroke', d.target.style.fill);

      /* tooltip.style('visibility', 'visible').text(
        `${propTrans({
          prop: d.target.attributes['data-label'].value,
          scope: 'dashboard',
        })} ${rounder(d.target.attributes['data-value'].value)}`
      ); */
      tooltip
        .style('visibility', 'visible')
        .text(
          `${d.target.attributes['data-label'].value} ${rounder(
            d.target.attributes['data-value'].value
          )}%`
        );
    };

    const mouseLeaveCallback = (d) => {
      d3.select(d.target).attr('stroke-width', '0');
      tooltip.style('visibility', 'hidden');
    };

    d3.select(`#${chartId}`)
      .selectAll('path')
      .on('mouseenter', mouseEnterCallback)
      .on('mousemove', (event) => {
        tooltip.style('top', `${event.pageY}px`).style('left', `${event.pageX + 10}px`);
      })
      .on('mouseleave', mouseLeaveCallback);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return (
    <div>
      <svg id={chartId} preserveAspectRatio='xMinYMin meet' viewBox='0 0 200 200'></svg>
    </div>
  );
};

const ValutazioneHeader = ({ activeStep, projectId, projectStatus, sectorCode, proponentCode, 
   translations, isUpdated, setIsUpdated, clientId, requestId, updatedAt }) => {
  const [progress, setProgress] = useState({});
  const [dataGeneral, setDataGeneral] = useState({});
  const [dataESG, setDataESG] = useState({});
  const [dataSROI, setDataSROI] = useState({});
  const [donutProgress, setDonutProgress] = useState([]);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const fullDate = new Date(updatedAt);
  const date = `${fullDate.getDate()}-${fullDate.getMonth() + 1}-${fullDate.getFullYear()}`;
  const hours = `${fullDate.getHours()}:${fullDate.getMinutes()}`;
  //console.log('progress', progress);
  // console.log('donutProgress', donutProgress);
  // console.log('translations', translations);

  const handleCloseModal = () => {
    setShowModalAlert(false);
    setIsChecked(false);
  };

  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (projectId || isUpdated) {
      const percentageSROI = await getPercentageSROI(projectId);
      // console.log('percentageSROI', percentageSROI);
      //const percentageSROI = 0;

      const percentageESG = await getPercentageESG(projectId);
      //console.log('percentageESG', percentageESG);

      setProgress({
        general: {
          total: 100,
          done: 100,
          notdone: 0,
          threshold: 80,
        },
        esg: {
          total: 100,
          done: percentageESG,
          notdone: 100 - percentageESG,
          threshold: 40,
        },
        sroi: {
          total: 100,
          done: percentageSROI.percentage,
          notdone: 100 - percentageSROI.percentage,
          threshold: 100,
        },
      });

      setIsUpdated(false);
    } else {
      setProgress({
        general: {
          total: 100,
          done: 0,
          notdone: 100,
          threshold: 80,
        },
        esg: {
          total: 100,
          done: 0,
          notdone: 100,
          threshold: 40,
        },
        sroi: {
          total: 100,
          done: 0,
          notdone: 100,
          threshold: 100,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, isUpdated]);

  useEffect(() => {
    // console.log('progress', progress);

    if (Object.keys(progress).length > 0) {
      setDataGeneral(progress.general);
      setDataESG(progress.esg);
      // setDataSROI(progress.sroi)
      proponentCode === 'ET' && progress.general.done === 100
        ? setDataSROI(progress.sroi, (progress.sroi.done = 100), (progress.sroi.notdone = 0))
        : setDataSROI(progress.sroi);

      let tempDonutProgress = [];

      // if (sectorCode === 'EVENTI' || proponentCode === 'ET') {
      //   tempDonutProgress = [progress.general.done, progress.esg.done;
      // } else {
      //   tempDonutProgress = [progress.general.done, progress.esg.done, progress.sroi.done];
      // }
      tempDonutProgress = [progress.general.done, progress.esg.done, progress.sroi.done];
      setDonutProgress(tempDonutProgress);
    }
  }, [progress, proponentCode, sectorCode, isUpdated]);

  const changeToSubmitted = async (e) => {
    e.preventDefault();

    InfoToast('Salvataggio...', 'Stiamo apportando le modifiche');

    const result = await putChangeStatusOnSubmitted(projectId);
    //console.log('result', result);

    if (result) {
      //console.log('localStorage.getItem(ics_role)', typeof localStorage.getItem('ics_role'), localStorage.getItem('ics_role'));

      if (localStorage.getItem('ics_role') === '2' || localStorage.getItem('ics_role') === '3') {
        SuccessToast('Operazione conclusa', 'Progetto inviato correttamente');
      }

      setTimeout(() => {
        if (localStorage.getItem('ics_role') === '1') {
          logout();
          //window.location.assign(`/thanks`);
          window.location = `/thanks?ndg=${clientId}&rapporto=${requestId}&date=${date}&hours=${hours}`;
        } else if (localStorage.getItem('ics_role') === '2' || localStorage.getItem('ics_role') === '3') {
          window.location.assign('/progetti');
        }

        //window.location.reload();
      }, 1000);
    } else {
      DangerToast('Attenzione', 'Errore nella sottomissione del progetto');
    }
  };

  const checkLabel = (
    <>
      <p className='check-text'>
         “{translations.filter((trans) => trans.code === 'ICS108').map((trans) => trans.description)}&nbsp;
         <a className='a-check-text' href={translations.filter((trans) => trans.code === 'ICS109').map((trans) => trans.text)} target="_blank" rel="noreferrer">
            {translations.filter((trans) => trans.code === 'ICS109').map((trans) => trans.text)}
         </a>”
      </p>
    </>
  );

  return (
    <div className='simple-box impact mt-0'>
      <div className='d-flex flex-wrap justify-content-center'>
        <div className='chartBox impactChart mx-4 my-3'>
          <a href={projectId ? '/valutazione/' + projectId : '#'}>
            <h5>
              1.{' '}
              {translations.filter((trans) => trans.code === 'ICS001').map((trans) => trans.text)}
            </h5>
          </a>
          {Object.keys(dataGeneral).length > 0 && Object.keys(progress).length > 0 ? (
            <AnimatedDonutChart
              chartId='general'
              data={dataGeneral}
              current={activeStep === 'general'}
              progress={progress}
              style={{ marginTop: '1rem' }}
            />
          ) : null}

          {activeStep === 'general' && projectStatus !== 'SUBMITTED' && (
            <Button form='dati-generali-form' id='saveAll' type='submit' className='mt-3'>
              SALVA
            </Button>
          )}
        </div>

        <div className='chartBox impactChart mx-4 my-3'>
          <a href={projectId ? '/valutazione/' + projectId + '/esg' : '#'}>
            <h5 style={{ whiteSpace: 'nowrap' }}>
              2.{' '}
              {translations.filter((trans) => trans.code === 'ICS029').map((trans) => trans.text)}
            </h5>
          </a>
          {Object.keys(dataGeneral).length > 0 ? (
            <AnimatedDonutChart
              chartId='esg'
              data={dataESG}
              current={activeStep === 'esg'}
              progress={progress}
              style={{ marginTop: '1rem' }}
            />
          ) : null}

          {activeStep === 'esg' && projectStatus !== 'SUBMITTED' && (
            <Button form='valutazione-esg-form' id='saveAll' type='submit' className='mt-3'>
              SALVA
            </Button>
          )}
        </div>

        {/* (sectorCode && sectorCode === 'EVENTI') ||
        (proponentCode && proponentCode === 'ET') ? null : */}
        {
          <div
            className='chartBox impactChart mx-4 my-3'
            style={{
              pointerEvents: sectorCode === 'EVENTI' || proponentCode === 'ET' ? 'none' : '',
            }}>
            <a href={projectId ? '/valutazione/' + projectId + '/sroi' : '#'}>
              <h5>
                3.{' '}
                {translations.filter((trans) => trans.code === 'ICS017').map((trans) => trans.text)}
              </h5>
            </a>
            {Object.keys(dataGeneral).length > 0 ? (
              <AnimatedDonutChart
                chartId='sroi'
                data={dataSROI}
                current={activeStep === 'sroi'}
                progress={progress}
                style={{ marginTop: '1rem' }}
              />
            ) : null}

            {activeStep === 'sroi' && proponentCode !== 'AUD' && projectStatus !== 'SUBMITTED' && (
              <Button form='valutazione-sroi-form' id='saveAll' type='submit' className='mt-3'>
                SALVA
              </Button>
            )}
          </div>
        }

        <div className='chartBox impactChart mx-4 my-3'>
          <h5>
            {/* {(sectorCode && sectorCode === 'EVENTI') || (proponentCode && proponentCode === 'ET')
              ? '3.'
              : '4.'}{' '} */}
            4.
            {translations.filter((trans) => trans.code === 'ICS030').map((trans) => trans.text)}
          </h5>
					
          {projectStatus !== 'SUBMITTED'? (
            
              <OverlayTrigger 
                placement="top" 
                delay={{ show: 200, hide: 400 }} 
                overlay={<Tooltip id="button-tooltip">Per inviare è necessario che tutte le sezioni siano completate al 100%</Tooltip>}
                {...(donutProgress.every(value => value === 100) ? 
                  {show: false} : null
                )}
              >
                <span className="d-inline-block" style={{margin: '4rem auto'}}>
                  <Button 
                    size="lg" 
                    type="submit" 
                    style={!donutProgress.every(value => value === 100) ? {pointerEvents: 'none'} : null} 
                    onClick={() => setShowModalAlert(true)}
                    disabled={!donutProgress.every(value => value === 100)}
                  >
                    INVIA
                  </Button>
                </span>
              </OverlayTrigger>
            
            ) : (
            <>
               <Row className="flex-column align-items-center" style={{marginTop: '4.5rem'}}>
                  <Button size="lg" id="submitted-button" variant="outline-dark" disabled>INVIATO</Button>
                  <Button variant='link' className="bg-transparent border-white mt-2" href={`/thanks?ndg=${clientId}&rapporto=${requestId}&date=${date}&hours=${hours}`} target="_blank">
                    <Print title={translations.filter(trans => trans.code === 'ICS097').map(trans => trans.text)} className="m-0 second-color" />
                  </Button>
               </Row>
            </>)
          }
        </div>
      </div>

      <Modal size='lg' show={showModalAlert} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Attenzione</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-5'>
          <p className='mb-4'>
            Effettuando la sottomissione non sarai più in grado di modificare i dati generali, le
            valutazioni e la compilazione del sondaggio associato.
          </p>
          <div>
            <Form.Group className='mb-3' controlId='checkPreSubmit'>
              <Form.Check
                type='checkbox'
                label={checkLabel}
                id='checkPreSubmit'
                onChange={() => setIsChecked(!isChecked)}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={handleCloseModal}>
            Annulla
          </Button>
          <Button variant='primary' disabled={!isChecked} onClick={(e) => changeToSubmitted(e)}>
            Conferma
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ValutazioneHeader;
